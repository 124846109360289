import GallerySlider from "components/general/GallerySlider";
import { useEffect, useState } from "react";
import GetData from "../servicdesk/getdata/GetData";
import { useParams } from "react-router-dom";
import $ from "jquery";
import AritinEditor from "components/general/AritinEditor";
import "./crm_comments.css";

const CrmComments = ({ data }) => {
  const [comments, setComments] = useState([]);
  const [changeField, setChangeField] = useState(false);
  const { id, type } = useParams();

  useEffect(() => {
    setComments(data);
  }, [data]);

  console.log(comments);

  const getCrmComments = async (value, action, id_item) => {
    const request_parameters = {
      module: "crm",
      block: type,
    };
    const request_attribute = {
      id: id,
      area: "comments",
      action: action,
      id_item: id_item,
    };
    const request_data = {
      value: value,
    };

    const result = await GetData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrComments =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setComments(arrComments);
  };

  function addDescription(textValue) {
    getCrmComments(textValue, "new_comment");
  }
  function editDescription(textValue, id_item) {
    getCrmComments(textValue, "edit_comment", id_item);
  }

  return (
    <>
      <div className="crm-comments-module">
        {comments
          ? Object.entries(comments).map(([index, commentsvalue]) => {
              return (
                <>
                  <div key={index}>
                    <div>
                      <div className="comment-text-user-date-message comments-box-text">
                        <div className="comment-user-date">
                          <div className="comment-user">
                            {commentsvalue.user
                              ? commentsvalue.user
                              : "Неизвестный пользователь"}
                          </div>
                          <div className="comment-date">
                            {commentsvalue.date
                              ? commentsvalue.date
                              : "Неизвестная дата"}
                          </div>
                        </div>
                        <div className="comment-message">
                          <div className="comment-message-aritineditor">
                            {commentsvalue.text || commentsvalue.files ? (
                              <AritinEditor
                                setChangeField={setChangeField}
                                changeField={changeField}
                                textProps={commentsvalue.text}
                                idProps={commentsvalue.id}
                                saveTextProps={editDescription}
                                //setChangeBoxProps={set_ticket_change_box}
                                propsFile={commentsvalue.files}
                                propsStartfile={"y"}
                                //deleteFileProps={deleteFileService}
                                propsAccess={commentsvalue.access}
                              />
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          : false}
        <div className="form-comments-block border_background p-3">
          <div className="aritin-editor-box-text">
            <AritinEditor
              setChangeField={setChangeField}
              changeField={changeField}
              textProps={""}
              saveTextProps={addDescription}
              newCommentProps={"y"}
              propsFile={[]}
              propsStartfile={"y"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CrmComments;
