import { useEffect, useState } from "react";
import GetData from "../servicdesk/getdata/GetData";
import { useParams } from "react-router-dom";
import CrmTopbar from "./CrmTopbar";
import CrmSidebar from "./CrmSidebar";
import CrmCentralBlock from "./CrmCentralBlock";
import CrmComments from "./CrmComments";
import "./crm_detail.css";

const CrmDetail = () => {
  const [crmPoints, setCrmPoints] = useState([]);
  const { id, type } = useParams();

  useEffect(() => {
    getCrmDetail();
  }, []);

  const getCrmDetail = async () => {
    const request_parameters = {
      module: "crm",
      block: type,
    };
    const request_attribute = {
      area: "all",
      id,
    };

    const result = await GetData(request_parameters, request_attribute);

    const arrCrm =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setCrmPoints(arrCrm);
  };

  return (
    <>
      <div>
        <div className="crm-topbar border_background">
          <CrmTopbar data={crmPoints.topbar} />
        </div>
        <div className="sidebar-central-block">
          <div className="crm-sidbar border_background">
            <CrmSidebar data={crmPoints.sidebar} />
          </div>
          <div className="crm-central-block">
            <div className="crm-central-block-value border_background">
              <CrmCentralBlock data={crmPoints.contact} tabs={crmPoints.tabs} />
            </div>
            <div className=" crm-central-block-comments border_background p-3">
              <CrmComments data={crmPoints.comments} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrmDetail;
