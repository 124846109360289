import { useEffect, useState } from "react";
import "./crm_sidebar.css";
import { useParams } from "react-router-dom";
import GetData from "../servicdesk/getdata/GetData";
import FunctionChange from "components/general/FunctionChange";

const CrmSidebar = ({ data }) => {
  const [sidebar, setSidbar] = useState([]);
  const { id, type } = useParams();

  useEffect(() => {
    setSidbar(data);
  }, [data]);

  const getCrmSidebar = async (edit_field, value) => {
    const request_parameters = {
      module: "crm",
      block: type,
    };
    const request_attribute = {
      id: id,
      area: "sidebar",
      action: "edit_sidebar",
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await GetData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrSidbar =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setSidbar(arrSidbar);
  };

  function getValue(edit_field, value, id_item) {
    getCrmSidebar(edit_field, value, id_item);
  }

  return (
    <>
      {sidebar ? (
        <div>
          <div className="sidebar-item">
            <div className="a-text-4">Дата создания</div>
            <div className="sidebar-item-value">
              {sidebar.date_create && sidebar.date_create.value
                ? sidebar.date_create.value
                : "пусто"}
            </div>
          </div>
          <div className="sidebar-item">
            <div className="a-text-4">Источник</div>
            <div className="sidebar-item-value">
              {sidebar.source && sidebar.source.value
                ? sidebar.source.value
                : "пусто"}
            </div>
          </div>
          <div className="sidebar-item">
            <div className="a-text-4">Ответственный</div>
            <div className="sidebar-item-value">
              <FunctionChange
              // getValue={getValue}
              // propsValue={
              // sidebar.assigned_user.value ? sidebar.assigned_user.value : ""
              //}
              //fieldType={sidebar.assigned_user.field}
              //propsInput="select"

              //------------------
              // propsIditem={listUser.id.value}

              // propsParamData={{
              //   module: "settings",
              //   block: "departments",
              //   for: "lh",
              // }}
              />

              {/* {sidebar.assigned_user && sidebar.assigned_user.value
                ? sidebar.assigned_user.value
                : "пусто"} */}
            </div>
          </div>
          <div className="sidebar-item">
            <div className="a-text-4">Сумма</div>
            <div className="sidebar-item-value">
              {sidebar.amount && sidebar.amount.value
                ? sidebar.amount.value
                : "пусто"}
            </div>
          </div>
          <div className="sidebar-item">
            <div className="a-text-4">Метки</div>
            <div className="sidebar-item-value">
              {sidebar.utm ? (
                <div>
                  <div>
                    utm_source_
                    {sidebar.utm.web && sidebar.utm.web.value
                      ? sidebar.utm.web.value
                      : "пусто"}
                  </div>
                  <div>
                    utm_medium_
                    {sidebar.utm.medium && sidebar.utm.medium.value
                      ? sidebar.utm.medium.value
                      : "пусто"}
                  </div>
                  <div>
                    utm_campaign_
                    {sidebar.utm.campaign && sidebar.utm.campaign.value
                      ? sidebar.utm.campaign.value
                      : "пусто"}
                  </div>
                  <div>
                    utm_content_
                    {sidebar.utm.content && sidebar.utm.content.value
                      ? sidebar.utm.content.value
                      : "пусто"}
                  </div>
                  <div>
                    utm_term_
                    {sidebar.utm.term && sidebar.utm.term.value
                      ? sidebar.utm.term.value
                      : "пусто"}
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
};
export default CrmSidebar;
