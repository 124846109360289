export const arrSearchSelectors = {
  placeholder: {
    title: "Текст по умолчанию в поле поиска*",
    placeholder: "Например: Поиск по сайту",
    faq: "Текст",
  },
  page_search_on: {
    title: "Заменять страницу поиска*",
    placeholder: "Заменять страницу поиска",
    faq: "Текст",
  },
  page_search_url: {
    title: "URL страница поиска*",
    placeholder: "Например: /search/?request=",
    faq: "Текст",
  },
  page_search_selector: {
    title: "Путь до блока с результатами выдачи на странице поиска*",
    placeholder: "Например: .body > .search_page > .content",
    faq: "Текст",
  },
  selector: {
    title: "Путь до блока с поисковой формой*",
    placeholder: "Например: .body > .header > header_search > input",
    faq: "Текст",
  },
};
