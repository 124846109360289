import madeInRF from "../../../image/img/made_in_rf.jpeg";
import "./support.css";
import { useEffect } from "react";

function Contactsupport() {
  return (
    <>
      <div class="support">
        <div className="a-text-1 p-2">Поддержка</div>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 support-block-top">
              <div className="pb-3">
                <div className="col-12 a-border-bg p-3">
                  <div>Уважаемые пользователи!</div>
                  <div>
                    При возникновении вопросов, обратитесь к нам удобным для Вас
                    способом:
                  </div>
                </div>
                <div className="col-12"></div>
              </div>
              <div className="pb-1">
                <div className="col-12 a-border-bg p-2">
                  <div>
                    <b> E-mail:</b> info@aritin.ru
                  </div>
                </div>
                <div className="col-12"></div>
              </div>
              <div className="pb-1">
                <div className="col-12 a-border-bg p-2">
                  <div>
                    <b>Telegram:</b>{" "}
                    <a href="https://t.me/aritin_support">@aritin_support</a>
                  </div>
                </div>
                <div className="col-12"></div>
              </div>
              <div className="">
                <div className="col-12 a-border-bg p-2">
                  <div>
                    <b>JivoChat:</b> Воспользуйтесь чатом в низу экрана
                  </div>
                </div>
                <div className="col-12"></div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="">
                <div className="col-12 a-border-bg p-2">
                  <div>
                    <div className="p-2">
                      Программное обеспечение «Аритин» полностью разработано в
                      России, исключительно российскими специалистами. Серверы,
                      на которых работает система, размещаются на территории
                      Российской Федерации.
                    </div>
                    <div className="p-2">
                      Мы учли много нюансов, чтобы стабильная работа нашей
                      системы обеспечивалась даже в самых непростых
                      геополитических ситуациях.
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row p-2">
                    <div className="col-2"></div>
                    <div className="col-8 madeinrf-support">
                      <img src={madeInRF} alt="made in RF" />
                    </div>
                    <div className="col-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactsupport;
