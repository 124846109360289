import { useEffect, useState } from "react";
import GetData from "../servicdesk/getdata/GetData";
import "./search-site.css";
import "../../general/switch/switch.css";
import FunctionChange from "components/general/FunctionChange";
import { FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  AiOutlineLineChart,
  AiOutlinePicCenter,
  AiOutlineSetting,
} from "react-icons/ai";

function SearchSite() {
  const [searchSiteList, setSearchSiteList] = useState([]);
  const [download, setDownload] = useState(false); // ожидание получении данных

  useEffect(() => {
    getSearchSite();
  }, []);

  const getSearchSite = async (
    action,
    id_item,
    searchValue,
    edit_field,
    value
  ) => {
    if (download === true) {
    } else {
      setDownload(true);
      const request_parameters = {
        module: "search",
        block: "main",
      };
      const request_attribute = {
        action: action,
        id_item: id_item,
      };
      const request_data = {
        search: searchValue,
        edit_field: edit_field,
        value: value,
      };
      const result = await GetData(
        request_parameters,
        request_attribute,
        request_data
      );

      const arrsearchSiteList =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];

      setSearchSiteList(arrsearchSiteList);
      setDownload(false);
    }
  };
  const handleChange = (event) => {
    getSearchSite("", "", event.target.value);
  };

  function getValue(fieldType, value, id_item) {
    getSearchSite("edit", id_item, "", fieldType, value);
  }

  return (
    <>
      <div className="search_all_content search_module">
        <div className="container">
          <div className="header-search">
            <div className="title_h1_search_add">
              <div className="a-text-1 a-section-title">Модуль поиска</div>
            </div>

            <div className="block-input-btn-block">
              <div className="block-input-btn">
                <input
                  id="custom-input"
                  type="text"
                  onChange={handleChange}
                  placeholder="Найти"
                />
              </div>
              <div className="a-btn_block_add-area">
                <div
                  className="a-btn_block_add"
                  onClick={() => getSearchSite("new_site")}
                >
                  Добавить сайт
                </div>
              </div>
            </div>
          </div>

          <div className={`"block-search ${download ? "blur-search " : ""}`}>
            {searchSiteList ? (
              <div>
                {searchSiteList.map((searchsitelistmap, index) =>
                  searchsitelistmap ? (
                    <div key={index} className="area-search-items">
                      <div className="border_background block-search-items">
                        <div className="search-items">
                          <div className="search-item">
                            <div
                              onClick={() =>
                                getSearchSite(
                                  "edit",
                                  searchsitelistmap.id
                                    ? searchsitelistmap.id
                                    : false,
                                  "",
                                  "active",
                                  searchsitelistmap.active === "n" ? "y" : "n"
                                )
                              }
                              className={`switch ${
                                searchsitelistmap.active &&
                                searchsitelistmap.active === "y"
                                  ? "active"
                                  : ""
                              }`}
                            ></div>

                            <div className="rrrrr">
                              <FunctionChange
                                getValue={getValue}
                                propsValue={
                                  searchsitelistmap.site_name
                                    ? searchsitelistmap.site_name
                                    : "Впишите название сайта"
                                }
                                fieldType={"site_name"}
                                propsIditem={searchsitelistmap.id}
                              />
                            </div>
                            <div>
                              <FunctionChange
                                getValue={getValue}
                                propsValue={
                                  searchsitelistmap.domain
                                    ? searchsitelistmap.domain
                                    : "Впишите домен сайта"
                                }
                                fieldType={"domain"}
                                propsIditem={searchsitelistmap.id}
                              />
                            </div>
                          </div>

                          <div
                            className="block_delete_fitrash search-btn-delete"
                            onClick={() => {
                              if (window.confirm("Удалить?")) {
                                getSearchSite("del_site", searchsitelistmap.id);
                              }
                            }}
                          >
                            <FiTrash />
                          </div>
                        </div>
                        <div className="search-info-block">
                          <div className="search-info">
                            <div className="search-info-icon">
                              <AiOutlineLineChart />
                            </div>
                            <div className="search-info-text">
                              <Link
                                to={`/statistics_search/${searchsitelistmap.id}/`}
                              >
                                Статистика
                              </Link>
                            </div>
                          </div>
                          <div className="search-info">
                            <div className="search-info-icon">
                              <AiOutlinePicCenter />
                            </div>
                            <div className="search-info-text">
                              <Link
                                to={`/widget_feed_search/${searchsitelistmap.id}/`}
                              >
                                Фиды для поиска
                              </Link>
                            </div>
                          </div>
                          <div className="search-info">
                            <div className="search-info-icon">
                              <AiOutlineSetting />
                            </div>
                            <div className="search-info-text">
                              <Link
                                to={`/widget_settings_search/${searchsitelistmap.id}/`}
                              >
                                Настройки виджета
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )
                )}
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchSite;
