import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { RiMenuSearchLine } from "react-icons/ri";
import {
  IoDuplicateOutline,
  IoDocumentTextOutline,
  IoConstructOutline,
  IoWalletOutline,
  IoAlbumsOutline,
  IoFolderOpenOutline,
  IoPeopleOutline,
  IoGitMergeOutline,
  IoBookOutline,
  IoInformation,
} from "react-icons/io5";
import logo from "../../../image/img/logo.png";
import "./menu.css";

function Menu({ client, module, propsMobileMenu, propsSetMobileMenu, propsExpand }) {
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if ($(event.target).hasClass("sub_section_text_title")) {
      propsSetMobileMenu(false);
    }
  };

  const sections = [];

  if (client && module && module.task && client !== "inside") {
    sections.push({
      title: "",
      isOpen: true,

      subSections: [
        {
          link: "/ticket/new/",
          text: "Создать заявку",
          icon: <IoDuplicateOutline />,
          id: "new_ticketsfast",
        },
      ],
    });
  }

  if (client && module && module.task && client === "inside") {
    if (module.task === "y") {
      sections.push({
        title: "",
        isOpen: true,

        subSections: [
          {
            link: "/task/new/",
            text: "Создать задачу",
            icon: <IoDuplicateOutline />,
            id: "new_tickets",
          },
          {
            link: "/ticket/new/",
            text: "Создать заявку",
            icon: <IoDuplicateOutline />,
            id: "new_ticketsfast",
          },
        ],
      });

      if (module.task === "y" && module.ticket !== "y") {
        sections.push({
          title: "Задачи и заявки",
          isOpen: true,

          subSections: [
            {
              link: "/task/",
              text: "Задачи",
              icon: <IoDocumentTextOutline />,
            },
            {
              link: "/template_task/",
              text: "Шаблоны",
              icon: <IoDocumentTextOutline />,
            },
          ],
        });
      }
      if (module.task === "y" && module.ticket === "y") {
        sections.push({
          title: "Задачи и заявки",
          isOpen: true,

          subSections: [
            {
              link: "/ticket/",
              text: "Заявки",
              icon: <IoDocumentTextOutline />,
            },
            {
              link: "/task/",
              text: "Задачи",
              icon: <IoDocumentTextOutline />,
            },
            {
              link: "/template_task/",
              text: "Шаблоны",
              icon: <IoDocumentTextOutline />,
            },
          ],
        });
      }

      sections.push({
        title: "Компания",
        isOpen: true,

        subSections: [
          {
            link: "/structure/",
            text: "Структура",
            icon: <IoGitMergeOutline />,
          },
          {
            link: "/staff/",
            text: "Сотрудники",
            icon: <IoPeopleOutline />,
          },
          {
            link: "/roles/",
            text: "Права и роли",
            icon: <IoAlbumsOutline />,
          },
        ],
      });

      sections.push({
        title: "Контрагенты",
        isOpen: true,

        subSections: [
          {
            link: "/counterparties/",
            text: "Контрагенты",
            icon: <IoFolderOpenOutline />,
          },
        ],
      });
    }

    if (module.search === "y") {
      sections.push({
        title: "Модуль поиска",
        isOpen: true,

        subSections: [
          {
            link: "/search/",
            text: "Поиск на сайт",
            icon: <RiMenuSearchLine />,
          },
        ],
      });
    }

    sections.push({
      title: "Информация",
      isOpen: true,

      subSections: [
        {
          link: "/knowledge/",
          text: "База знаний",
          icon: <IoInformation />,
        },
      ],
    });

    sections.push({
      title: "Параметры",
      isOpen: true,

      subSections: [
        {
          link: "/payment/",
          text: "Оплата",
          icon: <IoWalletOutline />,
        },
        {
          link: "/settings/",
          text: "Настройки",
          icon: <IoConstructOutline />,
        },
      ],
    });

    sections.push({
      title: "Поддержка",
      isOpen: true,

      subSections: [
        {
          link: "/contact_support/",
          text: "Поддержка",
          icon: <IoBookOutline />,
        },
      ],
    });
  }

  return (
    <>
      <div className={`sidebar_menu border_right_background ${propsMobileMenu === true ? "active" : ""} `}>
        <div className="sidebar_menu_items">
          {sections.map((section, index) => (
            <div key={index} className="menu_section">
              <div className={`section_title__section_menu ${section.title === "" ? "none" : ""} `}>
                <div className="section_icon_tittle_text">
                  <div className="section_text_tittle">{section.title}</div>
                </div>
              </div>

              {section.isOpen && section.subSections !== null ? (
                <div className="sub_sections">
                  {section.subSections !== null
                    ? section.subSections.map((subSection, subIndex) =>
                        subSection && subSection.text ? (
                          <div key={`subLinkBlok-${subIndex}`} className="sub_sections_item">
                            <Link to={subSection.link} key={`subLink-${subIndex}`}>
                              <div
                                className={
                                  "sub_section_icon__sub_section_text_title subSection_" + subSection.id
                                }
                              >
                                <div className="sub_section_icon">{subSection.icon}</div>
                                <div className="sub_section_text_title">{subSection.text}</div>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          false
                        )
                      )
                    : false}
                </div>
              ) : (
                false
              )}
            </div>
          ))}

          <div className="app_logo_block">
            <div className="app_logo">
              <img src={logo} alt="logo" />
              <div className="copyright">
                <div className="copyright_text">&copy; 2024 Aritin.ru</div>
                <div className="made_in_rf"> Сделано в РФ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
