import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GetData from "../servicdesk/getdata/GetData";
import "./widget-statistics-search.css";

function WidgetStatisticsSearch() {
  const { id } = useParams();
  const [listWidgetStatSearch, setListWidgetStatSearch] = useState([]);
  const [download, setDownload] = useState(false); // ожидание получении данных
  const [listWidgetPagination, setListWidgetPagination] = useState([]);

  useEffect(() => {
    getWidgetStatSearch();
  }, []);

  const getWidgetStatSearch = async (searchValue, page) => {
    if (download === true) {
    } else {
      setDownload(true);
      const request_parameters = {
        module: "search",
        block: "statistics",
      };
      const request_attribute = {
        id_item: id,
        page: page,
      };
      const request_data = {
        search: searchValue,
      };
      const result = await GetData(
        request_parameters,
        request_attribute,
        request_data
      );

      console.log(result);

      const arrWidgetStatSearch =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];
      const arrWidgetStatPagination =
        result && result.request_data && result.request_data.pagination
          ? result.request_data.pagination
          : [];

      setListWidgetStatSearch(arrWidgetStatSearch);
      setListWidgetPagination(arrWidgetStatPagination);
      setDownload(false);
    }
  };

  const handleChange = (event) => {
    getWidgetStatSearch(event.target.value);
  };

  // Пагинация

  function widgetSearchPagination() {
    if (!listWidgetPagination) return null;

    const currentPage = parseInt(listWidgetPagination.current_page, 10) || 1;
    const totalCountOnPages = listWidgetPagination.count_on_page || 1;
    const totalCount = listWidgetPagination.total_count || 0;
    const totalPages = Math.ceil(totalCount / totalCountOnPages);

    let pages = [];

    if (totalPages > 1) {
      // Добавляем первую страницу
      pages.push(1);
      pages.push(2);
      pages.push(3);

      // Добавляем троеточие, если текущая страница далеко от первых двух на 7
      if (currentPage > 7) {
        pages.push("...");
      }

      // Вычисляем диапазон страниц вокруг текущей
      let start = Math.max(4, currentPage - 3); // Гарантируеv, что start не будет меньше 4 и для баланса показываем -3 числа до текущей страницы
      let end = Math.min(totalPages - 3, currentPage + 3); // Гарантируем, что end не будет больше, чем последние три страницы и для баланса показываем +3 числа от текущей страницы

      // Выводим результат средней части
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      // Если разрыв перед последними тремя страницами, добавляем троеточие
      if (end < totalPages - 4) {
        pages.push("...");
      }

      // Добавляем последние 3 страницы
      for (let i = Math.max(totalPages - 2, end + 1); i <= totalPages; i++) {
        pages.push(i);
      }
    }

    return (
      <div className="widget-search-pagination-block">
        <div className="widget-search-pagination-totalcount">
          Всего фраз: {totalCount}
        </div>
        <div className="widget-search-pagination-total-pages">
          {pages.map((page, index) => (
            <div
              key={index}
              className={`pagination-item ${
                currentPage === page ? "active" : ""
              }  ${
                typeof page === "number" ? "pagination-number-search" : ""
              } `}
              onClick={() => getWidgetStatSearch("", page)}
            >
              {page}
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (listWidgetPagination && listWidgetPagination.count_on_page > 1) {
    widgetSearchPagination();
  }

  return (
    <>
      <div className="search_statistics_all_content">
        <div className="container">
          <div className="header-search">
            <div className="title_h1_search_add">
              <div className="a-text-1 a-section-title">Статистика</div>
            </div>

            <div className="block-input-btn-block">
              <div className="block-input-btn">
                <input
                  id="custom-input"
                  type="text"
                  onChange={handleChange}
                  placeholder="Найти"
                />
              </div>
            </div>
          </div>

          <div className="border_background widget-statistics-body-content">
            <div className="widget-table-statistics-items">
              <div className="widget-statistics-item">Поисковой запрос</div>
              <div className="widget-statistics-item">Количество запросов</div>
              <div className="widget-statistics-item">Результат</div>
              <div className="widget-statistics-item">Переходов</div>
            </div>
            <div>
              {listWidgetStatSearch
                ? listWidgetStatSearch.map((liststatsearchmap, index) =>
                    liststatsearchmap ? (
                      <div
                        key={index}
                        className="widget-table-statistics-items"
                      >
                        <div className="widget-statistics-item">
                          {liststatsearchmap.value
                            ? liststatsearchmap.value
                            : "данные потеряны"}
                        </div>
                        <div className="widget-statistics-item">
                          {liststatsearchmap.count
                            ? liststatsearchmap.count
                            : "данные потеряны"}
                        </div>
                        <div className="widget-statistics-item">
                          {liststatsearchmap.result
                            ? liststatsearchmap.result
                            : "данные потеряны"}
                        </div>
                      </div>
                    ) : (
                      false
                    )
                  )
                : false}
            </div>
          </div>

          <div>
            <div className="widget-search-pagination-area">
              {widgetSearchPagination()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetStatisticsSearch;
