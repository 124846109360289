import { useEffect, useState } from "react";
import GetData from "../servicdesk/getdata/GetData";
import { useParams } from "react-router-dom";
import FunctionChange from "components/general/FunctionChange";
import { arrSearchSelectors } from "../servicdesk/const/DataSearchWidget";
import "./widget.css";

function WidgetSettingsSearch() {
  const { id } = useParams();
  const [listWidgetSearch, setListWidgetSearch] = useState([]);
  const [download, setDownload] = useState(false); // ожидание получении данных
  useEffect(() => {
    getWidgetSearch(id);
  }, []);

  const getWidgetSearch = async (edit_field, value) => {
    if (download === true) {
    } else {
      setDownload(true);
      const request_parameters = {
        module: "search",
        block: "widget",
      };
      const request_attribute = {
        action: "edit",
        id_item: id,
      };
      const request_data = {
        edit_field: edit_field,
        value: value,
      };
      const result = await GetData(
        request_parameters,
        request_attribute,
        request_data
      );

      const arrWidgetSearch =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];

      setListWidgetSearch(arrWidgetSearch);
      setDownload(false);
    }
  };

  function getValue(fieldType, value) {
    getWidgetSearch(fieldType, value);
  }
  return (
    <>
      <div className="widget_all_content search_module">
        <div className="container">
          {listWidgetSearch ? (
            <div>
              <div>
                {listWidgetSearch.site_name ? (
                  <div>
                    <div className="a-text-1 a-section-title">
                      Настройка виджета - {listWidgetSearch.site_name.value}
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
              <div className="border_background widget-content">
                <div className="widget-replaceable-blocks">
                  <div className="title-block-name">Подменяемые блоки</div>
                  {listWidgetSearch.selectors_popup
                    ? listWidgetSearch.selectors_popup.map(
                        (blocksmap, index) => {
                          const dataSelectorItem =
                            arrSearchSelectors[blocksmap.field];
                          return (
                            <div
                              key={index}
                              className="widget-replaceable-item-block"
                            >
                              <div className="widget-block-item">
                                <div className="widget-replaceable-item">
                                  {dataSelectorItem.title ? (
                                    <div className="widget-selector-name">
                                      {dataSelectorItem.title}
                                    </div>
                                  ) : (
                                    false
                                  )}

                                  <FunctionChange
                                    getValue={getValue}
                                    propsValue={
                                      blocksmap.value ? blocksmap.value : false
                                    }
                                    fieldType={
                                      blocksmap.field ? blocksmap.field : false
                                    }
                                  />
                                </div>
                                <div>Текст подсказка</div>
                              </div>
                            </div>
                          );
                        }
                      )
                    : false}
                  <div className="a-btn_block_add-widget">Добавить</div>
                </div>

                {listWidgetSearch.blocks
                  ? listWidgetSearch.blocks.map((blocksmap, index) => {
                      const dataSelectorItem =
                        arrSearchSelectors[blocksmap.field];

                      return blocksmap.field &&
                        blocksmap.field === "page_search_on" ? (
                        <div key={index} className="widget-switch-block">
                          <div
                            onClick={() =>
                              getWidgetSearch(
                                "page_search_on",
                                blocksmap.value === "n" ? "y" : "n"
                              )
                            }
                            className={`switch ${
                              blocksmap.value && blocksmap.value === "y"
                                ? "active"
                                : ""
                            }`}
                          ></div>
                          <div>Заменить посадочную страницу поиска</div>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="widget-replaceable-item-block"
                        >
                          <div className="widget-block-item">
                            <div className="widget-replaceable-item">
                              {dataSelectorItem.title ? (
                                <div className="widget-selector-name">
                                  {dataSelectorItem.title}
                                </div>
                              ) : (
                                false
                              )}

                              <FunctionChange
                                getValue={getValue}
                                propsValue={
                                  blocksmap.value
                                    ? blocksmap.value
                                    : blocksmap.placeholder
                                }
                                fieldType={
                                  blocksmap.field ? blocksmap.field : false
                                }
                              />
                            </div>

                            <div>Текст подсказка</div>
                          </div>
                        </div>
                      );
                    })
                  : false}
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </>
  );
}
export default WidgetSettingsSearch;
