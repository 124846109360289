import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import TableTickets from "components/unit/servicdesk/tickets/TableTickets";
import Ticket from "components/unit/servicdesk/tickets/Ticket";
import Counterparties from "components/unit/servicdesk/clients/Сounterparties";
import Structure from "components/unit/structure/Structure";
import Staff from "components/unit/staff_user/Staff";
import User from "components/unit/staff_user/User";
import Roles from "components/unit/roles/Roles";
import Сounterpartie from "components/unit/servicdesk/clients/Сounterpartie";
import Payment from "components/unit/payment/Payment";
import Contactsupport from "components/unit/support/Contactsupport";
import Settings from "components/unit/settings/Settings";
import TemplateTicket from "components/unit/servicdesk/template_ticket/TemplateTicket";
import TemplateTickets from "components/unit/servicdesk/template_ticket/TemplateTickets";
import TicketsFast from "components/unit/servicdesk/ticketsfast/TicketsFast";
import TableTicketsFast from "components/unit/servicdesk/ticketsfast/TableTicketsFast";
import Knowledge from "components/unit/knowledge/Knowledge";
import PlugAccess from "./PlugAccess";
import NotFound from "./NotFound";
import NotPaid from "./NotPaid";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import CrmDetail from "components/unit/crm/CrmDetail";
import CrmList from "components/unit/crm/CrmList";
import SearchSite from "components/unit/search-site/SearchSite";
import WidgetSettingsSearch from "components/unit/search-site/WidgetSettingsSearch";
import WidgetFeedSearch from "components/unit/search-site/WidgetFeedSearch";
import WidgetStatisticsSearch from "components/unit/search-site/WidgetStatisticsSearch";
import Unauthorized from "./Unauthorized";
import ModuleNotPaid from "./stubs/ModuleNotPaid";

function Routing({ client, module, paid }) {
  const location = useLocation();

  useEffect(() => {
    // Запускаем Jivo на странице contact_support
    if (window.location.href.includes("contact_support")) {
      if ($("body > jdiv").length) {
        $("body > jdiv").css("display", "inline");
      } else {
        const script = document.createElement("script");
        script.src = "//code.jivo.ru/widget/CaqZJH9Qmu";
        script.async = true;
        document.body.appendChild(script);
      }

      return () => {
        if ($("body > jdiv").length) {
          $("body > jdiv").css("display", "none");
        }
      };
    }
  }, [location]);

  const protectedRoute = (Component) => (client === "inside" ? <Component /> : <PlugAccess />);

  const taskRoute = (Component) => (module?.task === "y" ? protectedRoute(Component) : <ModuleNotPaid />);

  return (
    <div className="content-routing">
      {paid !== "N" ? (
        <Routes>
          {/* Задачи и заявки */}
          <Route path="/" element={protectedRoute(TableTickets)} />
          <Route path="/task/" element={taskRoute(TableTickets)} />
          <Route path="/task/:id/" element={taskRoute(Ticket)} />
          <Route path="/template_task/" element={taskRoute(TemplateTickets)} />
          <Route path="/template_task/:id/" element={taskRoute(TemplateTicket)} />
          <Route
            path="/ticket/"
            element={
              module?.task === "y" ? (
                module?.ticket === "y" ? (
                  <TableTicketsFast />
                ) : (
                  <ModuleNotPaid />
                )
              ) : (
                <ModuleNotPaid />
              )
            }
          />
          <Route
            path="/ticket/:id/"
            element={
              module?.task === "y" ? (
                module?.ticket === "y" ? (
                  <TicketsFast />
                ) : (
                  <ModuleNotPaid />
                )
              ) : (
                <ModuleNotPaid />
              )
            }
          />

          {/* Информация */}
          <Route path="/knowledge/" element={protectedRoute(Knowledge)} />
          <Route path="/knowledge/:id/" element={protectedRoute(Knowledge)} />

          {/* Контрагенты */}
          <Route path="/counterparties/" element={taskRoute(Counterparties)} />
          <Route path="/counterparties/:id/" element={taskRoute(Сounterpartie)} />

          {/* CRM */}
          <Route path="/crm/:type/" element={protectedRoute(CrmList)} />
          <Route path="/crm_detail/:type/:id/" element={protectedRoute(CrmDetail)} />

          {/* Компания */}
          <Route path="/structure/" element={taskRoute(Structure)} />
          <Route path="/staff/" element={taskRoute(Staff)} />
          <Route
            path="/staff/:id/"
            element={taskRoute(() => (
              <User propsModule={module} />
            ))}
          />
          <Route path="/roles/" element={taskRoute(Roles)} />

          {/* Параметры */}
          <Route path="/payment/" element={protectedRoute(Payment)} />
          <Route path="/settings/" element={protectedRoute(Settings)} />

          {/* Модуль поиска */}
          {module?.search === "y" ? (
            <>
              <Route path="/search/" element={protectedRoute(SearchSite)} />
              <Route path="/widget_settings_search/:id/" element={protectedRoute(WidgetSettingsSearch)} />
              <Route path="/statistics_search/:id/" element={protectedRoute(WidgetStatisticsSearch)} />
              <Route path="/widget_feed_search/:id/" element={protectedRoute(WidgetFeedSearch)} />
            </>
          ) : (
            <Route path="/search/*" element={<ModuleNotPaid />} />
          )}

          {/* Поддержка */}
          <Route path="/contact_support/" element={<Contactsupport />} />

          {/* 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/payment/" element={protectedRoute(Payment)} />
          <Route path="/contact_support/" element={<Contactsupport />} />
          <Route path="*" element={<NotPaid />} />
        </Routes>
      )}
    </div>
  );
}

export default Routing;
