import { useState } from "react";

const CrmList = () => {
  //   useEffect(() => {
  //     getCrmList();
  //   }, []);

  //   const getCrmList = async (action, id_item, edit_field, value) => {
  //     const [crm, setCrm] = useState[[]];

  //     const request_parameters = {
  //       module: "crm",
  //       block: type,
  //     };
  //     const request_attribute = {
  //       action,
  //       id_item,
  //     };

  //     const request_data = {
  //       edit_field,
  //       value,
  //     };

  //     const result = await getData(
  //       request_parameters,
  //       request_attribute,
  //       request_data
  //     );

  //     const arrCrm =
  //       result && result.request_data && result.request_data.items
  //         ? result.request_data.items
  //         : [];

  //     setCrm(arrCrm);
  // };
  return (
    <>
      <div></div>
    </>
  );
};

export default CrmList;
