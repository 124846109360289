import { useState, useEffect, useRef } from "react";
import getData from "../servicdesk/getdata/GetData";
import $ from "jquery";
import FunctionChange from "components/general/FunctionChange";
import { FiTrash } from "react-icons/fi";
import ReactAudioPlayer from "react-audio-player";
import { PiChatCenteredDotsThin } from "react-icons/pi";
import { CiEdit } from "react-icons/ci";
import {
  IoChatbubblesOutline,
  IoCloseCircleOutline,
  IoAttachOutline,
  IoSendSharp,
  IoEllipsisHorizontal,
  IoCameraReverseOutline,
  IoCloseOutline,
  IoClose,
  IoArrowUndoOutline,
  IoChevronDownOutline,
  IoCheckmarkDoneOutline,
  IoAlertCircleOutline,
  IoChevronUpOutline,
} from "react-icons/io5";
import avatar from "../../../image/png/avatar.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import "./chat.css";
import MessageList from "./ChatMessageList";
import notification from "./audio/notification_chat.mp3";

function Chat({ propsMobileChat, propsSetMobileChat, propsExpand }) {
  const [arrItemsChat, setArrItemsChat] = useState([]);
  const [notificationChat, setNotificationChat] = useState([]);
  const [userChat, stateUserChat] = useState([]);
  const [neededRefresh, setNeededRefresh] = useState(null);
  const [activeChatForRefresh, setActiveChatForRefresh] = useState(null);
  const [notificationAudio, setNotificationAudio] = useState([]);

  const [selectedFileChat, setselectedFileChat] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("n");
  const [defaultTitle, setDefaultTitle] = useState(document.title);

  const [activeMobileChat, setActiveMobileChat] = useState(false);
  const [activeOpenMobileChat, setActiveOpenMobileChat] = useState(false);
  const [setupWindow, setSetupWindow] = useState(false);

  useEffect(() => {
    if (propsMobileChat === true) {
      getChat("1");
    }
    setActiveOpenMobileChat(false);
    setActiveMobileChat(propsMobileChat);
  }, [propsMobileChat]);

  useEffect(() => {
    if (neededRefresh) {
      if (activeChatForRefresh > 0) {
        getChat(activeChatForRefresh);
      } else {
        getChat();
      }
    }
  }, [activeChatForRefresh, neededRefresh]);

  useEffect(() => {
    const interval2 = setInterval(() => {
      updateTitle();
    }, 500);
    return () => {
      clearInterval(interval2);
    };
  }, [notificationTitle]);

  useEffect(() => {
    getChat();
    const interval = setInterval(() => {
      getNotofications();
    }, 1000);
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
      clearInterval(interval);
    };
  }, []);

  const getNotofications = async () => {
    const request_parameters = {
      module: "chat",
      block: "notifications",
    };

    const request_attribute = {
      active_chat: arrItemsChat.active_chat ? arrItemsChat.active_chat : 0,
    };

    const result = await getData(request_parameters, request_attribute);

    const arrNotifications =
      result && result.request_data && result.request_data.items && result.request_data.items.list
        ? result.request_data.items.list
        : [];
    const arrNotificationsAudio =
      result && result.request_data && result.request_data.items && result.request_data.items.list_audio
        ? result.request_data.items.list_audio
        : [];

    if (
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.update &&
      result.request_data.items.update > 0
    ) {
      setNeededRefresh(result.request_data.items.update);
    } else {
      setNeededRefresh(null);
    }

    let titleNotoficationsShow = false;

    Object.values(arrNotifications).forEach((e) => {
      if (e > 0) {
        titleNotoficationsShow = true;
      }
    });

    if (titleNotoficationsShow) {
      setNotificationTitle("y");
    } else {
      setNotificationTitle("n");
    }

    setNotificationChat(arrNotifications);

    setNotificationAudio(arrNotificationsAudio);
  };

  const clickHandler = (event) => {
    if ($(event.target).hasClass("add_chat") && window.confirm("Добавить новый чат?")) {
      getChat("", "add");
    }
    if ($(event.target).hasClass("delete_chat") && window.confirm("Удалить?")) {
      getChat($(event.target).attr("data-idchat"), "delete");
    }
    if ($(event.target).hasClass("btn_for_close_chat_area")) {
      getChat();
    }

    if ($(event.target).hasClass("button-participants")) {
      $(".participants_area").toggleClass("active");
    }
    if (
      $(".participants_area").hasClass("active") &&
      !$(event.target).is(".participant_item, .participants, .button-participants")
    ) {
      $(".participants_area").removeClass("active");
    }

    if ($(".list_item_user_chat").hasClass("list_item_user_chat")) {
      stateUserChat([]);
    }
  };

  const updateTitle = () => {
    if (notificationTitle === "y") {
      let link = document.querySelector("link[rel~='icon']");
      if (document.title !== "Новое сообщение!") {
        document.title = "Новое сообщение!";
        link.href = "/favicon_white.png";
      } else {
        document.title = defaultTitle;
        link.href = "/favicon.ico";
      }
    } else {
      if (document.title !== defaultTitle) {
        document.title = defaultTitle;
      }
    }
  };

  const getChat = async (id_chat, action, id_item, value) => {
    if (id_chat > 0 && activeChatForRefresh !== id_chat) {
      setActiveChatForRefresh(id_chat);
    }
    const request_parameters = {
      module: "chat",
      block: "chat_list",
    };

    /*
    if (activeMessageForAnswer && action === 'add_message') {
      id_item = activeMessageForAnswer;
      setselectedFileChat([]);
    } else if (action === 'add_message') {
      setselectedFileChat([]);
    }
      */

    if (arrItemsChat.active_chat && arrItemsChat.active_chat !== id_chat) {
      setselectedFileChat([]);
    }

    const request_attribute = {
      id_chat: id_chat,
      action: action,
      id_item: id_item,
    };

    const request_data = {
      value: value,
    };

    const result = await getData(request_parameters, request_attribute, request_data, selectedFileChat);
    const arrChat =
      result && result.request_data && result.request_data.items && result.request_data.items.active_chat
        ? result.request_data.items
        : [];

    /*
    let currentScrollBottom = false;
    if ($('.chat_right_middle')[0] && $('.chat_right_middle')[0].scrollHeight) {
      currentScrollBottom = $('.chat_right_middle')[0].scrollHeight - $('.chat_right_middle').scrollTop();
    }
*/
    setArrItemsChat(arrChat);

    stateUserChat([]);
  };

  function getValue(fieldType, value, id_item) {
    getChat(id_item, fieldType, "", value);
  }

  //------

  const checkCountRows = (selector) => {
    let grid = [];
    let resultCheck = "n";
    if (selector) {
      grid =
        document.querySelector(selector) && document.querySelector(selector).children
          ? document.querySelector(selector).children
          : [];
    }
    if (grid && grid.length > 0) {
      const baseOffset = grid[0].offsetTop;

      Array.from(grid).forEach((item) => {
        if (item.offsetTop > baseOffset) {
          resultCheck = "y";
        }
      });
    }
    return resultCheck;
  };

  const chatUser = async () => {
    const request_parameters = {
      module: "settings",
      block: "users",
      type: "get",
    };
    const request_attribute = {
      content: { value: "name" },
      for: "tickets",
    };
    const result = await getData(request_parameters, request_attribute);
    const arrItemsUserChat =
      result && result.request_data && result.request_data.items ? result.request_data.items : [];

    const arrItemsUserChatChecked = arrItemsUserChat.filter((arritemsuserchatvalue) => {
      const userExists =
        arritemsuserchatvalue &&
        arritemsuserchatvalue.id &&
        !arrItemsChat.user_list.some((user) => String(user.id_user) === String(arritemsuserchatvalue.id));

      return userExists;
    });

    stateUserChat(arrItemsUserChatChecked);
  };

  const changeImageChat = async (changeFile) => {
    const request_parameters = {
      module: "chat",
      block: "chat_list",
    };
    const request_attribute = {
      id_chat: arrItemsChat.active_chat,
      action: "change_image",
    };

    const request_data = [];

    const arrFileLogo = [changeFile.target.files[0]];

    const result = await getData(request_parameters, request_attribute, request_data, arrFileLogo);

    const arrChat =
      result && result.request_data && result.request_data.items && result.request_data.items.active_chat
        ? result.request_data.items
        : [];
    setArrItemsChat(arrChat);
    stateUserChat([]);
    setselectedFileChat([]);
  };

  return (
    <>
      <div
        className={`chat ${arrItemsChat.active_chat && arrItemsChat.active_chat > 0 ? "chat_active" : ""} ${
          activeMobileChat && activeMobileChat === true ? "active-mobile" : ""
        }`}
      >
        <Container className="container_chat">
          <Row>
            <Col sm={arrItemsChat.active_chat > 0 ? 4 : null} className="p-0 chat_center ">
              {arrItemsChat.active_chat > 0 ? (
                <div className="bg-dark tc chat_left pt-3">
                  <Stack gap={3} className="chat_left_btn-block-mobile">
                    <div
                      className="chat_left_btn"
                      onClick={() => {
                        getChat();
                        setActiveChatForRefresh(null);
                      }}
                    >
                      <div className="p-1 chat_p_2_svg">
                        <IoCloseCircleOutline />
                      </div>
                      <Card.Text onClick={() => propsSetMobileChat(false)}>Закрыть</Card.Text>
                    </div>
                    <div className="chat_left_btn add_chat">
                      <div className="p-1 chat_p_2_svg add_chat">
                        <IoChatbubblesOutline className="add_chat" />
                      </div>
                      <Card.Text className="add_chat">Создать</Card.Text>
                    </div>
                    <div className="chat_left_btn">
                      <div className="p-1 chat_p_2_svg">
                        <FiTrash
                          className="delete_chat"
                          data-idchat={
                            arrItemsChat.active_chat && arrItemsChat.active_chat > 0
                              ? arrItemsChat.active_chat
                              : false
                          }
                        />
                      </div>
                      <Card.Text
                        data-idchat={
                          arrItemsChat.active_chat && arrItemsChat.active_chat > 0
                            ? arrItemsChat.active_chat
                            : false
                        }
                      >
                        Удалить
                      </Card.Text>
                    </div>
                  </Stack>
                </div>
              ) : (
                false
              )}

              <div className="chat_center_area">
                <Stack gap={arrItemsChat.active_chat > 0 ? 0 : 3} className="chat_center_scroll">
                  {arrItemsChat.active_chat > 0 ? (
                    false
                  ) : (
                    <div>
                      {arrItemsChat &&
                      arrItemsChat.chat_list &&
                      arrItemsChat.chat_list[0] &&
                      arrItemsChat.chat_list[0].id ? (
                        false
                      ) : (
                        <div className="chat-item_list_chatname_main">
                          <div className="chat-item_list_chatname_noactive">
                            <PiChatCenteredDotsThin className="add_chat" title="Создать чат" />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {arrItemsChat && arrItemsChat.chat_list
                    ? arrItemsChat.chat_list.map((arritemschatvalue, index) =>
                        arritemschatvalue ? (
                          <div
                            className={`chat-item_list_chatname_main ${
                              arrItemsChat.active_chat === arritemschatvalue.id.value ? "active" : ""
                            }
                            ${arrItemsChat.active_chat > 0 ? "border_bottom_background" : ""}`}
                            key={index}
                            onClick={() => {
                              setActiveChatForRefresh(arritemschatvalue.id.value);
                              //getChat(arritemschatvalue.id.value);
                              $("#chat_textarea_comment").val("");
                              $(".chat_content").removeClass("active_setup");
                              setActiveOpenMobileChat(true);
                            }}
                          >
                            {arrItemsChat.active_chat && arrItemsChat.active_chat > 0 ? (
                              <div
                                className="chat-item_chatname_active"
                                onClick={() => {
                                  getChat(arritemschatvalue.id.value);
                                  $("#chat_textarea_comment").val("");
                                  $(".chat_content").removeClass("active_setup");
                                }}
                              >
                                {arritemschatvalue &&
                                arritemschatvalue.id &&
                                arritemschatvalue.id.value &&
                                notificationAudio[arritemschatvalue.id.value] &&
                                notificationAudio[arritemschatvalue.id.value] > 0 &&
                                (document.hidden ||
                                  arritemschatvalue.id.value !== arrItemsChat.active_chat) ? (
                                  <>
                                    <ReactAudioPlayer src={notification} autoPlay controls />
                                  </>
                                ) : (
                                  false
                                )}

                                <div className="chat-item_chatname_active_logo">
                                  {arritemschatvalue &&
                                  arritemschatvalue.image &&
                                  arritemschatvalue.image.value ? (
                                    <img
                                      className={` ${
                                        notificationChat[arritemschatvalue.id.value] > 0
                                          ? "message_new"
                                          : null
                                      }`}
                                      src={
                                        arritemschatvalue.image.value ? arritemschatvalue.image.value : false
                                      }
                                      alt="new message"
                                    ></img>
                                  ) : (
                                    <img
                                      src={avatar}
                                      className={` ${
                                        notificationChat[arritemschatvalue.id.value] > 0
                                          ? "message_new"
                                          : null
                                      }`}
                                      alt="logo"
                                    />
                                  )}
                                </div>
                                <div className="chat-item_chatname_active_info">
                                  <div className="chat-item_chatname_active_name">
                                    {" "}
                                    {arritemschatvalue &&
                                    arritemschatvalue.name &&
                                    arritemschatvalue.name.value
                                      ? arritemschatvalue.name.value
                                      : false}
                                  </div>
                                  <div className="chat-item_chatname_active_text">
                                    {arritemschatvalue && arritemschatvalue.message
                                      ? arritemschatvalue.message
                                      : "Нет сообщений"}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="chat-item_list_chatname_noactive"
                                onClick={() => getChat(arritemschatvalue.id.value)}
                              >
                                {arritemschatvalue &&
                                arritemschatvalue.id &&
                                arritemschatvalue.id.value &&
                                notificationAudio[arritemschatvalue.id.value] &&
                                notificationAudio[arritemschatvalue.id.value] > 0 ? (
                                  <>
                                    <ReactAudioPlayer src={notification} autoPlay controls />
                                  </>
                                ) : (
                                  false
                                )}

                                {arritemschatvalue &&
                                arritemschatvalue.image &&
                                arritemschatvalue.image.value ? (
                                  <img
                                    className={` ${
                                      notificationChat[arritemschatvalue.id.value] > 0 ? "message_new" : null
                                    }`}
                                    src={arritemschatvalue.image.value}
                                    alt="document"
                                  ></img>
                                ) : (
                                  <img
                                    src={avatar}
                                    className={` ${
                                      notificationChat[arritemschatvalue.id.value] > 0 ? "message_new" : null
                                    }`}
                                    alt="logo"
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          false
                        )
                      )
                    : false}
                </Stack>
              </div>
            </Col>
            {arrItemsChat.active_chat > 0 ? (
              <Col sm={8} className={`chat_right p-0 ${activeOpenMobileChat ? "active" : ""}`}>
                <div className="chat_right_area">
                  <div className="chat_right_top border-bottom">
                    <div className="row h-100">
                      <div className="col-10 h-100">
                        <div className="chat_right_top_name">
                          {arrItemsChat && arrItemsChat.active_chat_info && arrItemsChat.active_chat_info.name
                            ? arrItemsChat.active_chat_info.name
                            : false}
                        </div>
                        <div className="participants_area">
                          <div className="participants">
                            {arrItemsChat && arrItemsChat.user_list
                              ? arrItemsChat.user_list.map((user_listvalue, index) =>
                                  user_listvalue ? (
                                    <div
                                      key={index}
                                      className={`participant_item ${
                                        user_listvalue.online == "y" ? "online" : ""
                                      }`}
                                    >
                                      {user_listvalue.value}
                                    </div>
                                  ) : (
                                    false
                                  )
                                )
                              : false}
                          </div>
                          {checkCountRows(".participants") === "y" ? (
                            <div className="button-participants-style button-participants">
                              <span className="button-participants-no-active button-participants">
                                <IoChevronDownOutline className="button-participants-btn button-participants" />
                              </span>
                              <span className="button-participants-active button-participants">
                                <IoChevronUpOutline className="button-participants-btn button-participants" />
                              </span>
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      </div>

                      {arrItemsChat.active_chat && arrItemsChat.active_chat !== "1" ? (
                        <div
                          className="col-2"
                          onClick={() => {
                            //$('.chat_right_setup').toggleClass('active');
                            setSetupWindow((prevSetupWindow) => !prevSetupWindow);
                            $(".chat_right_top_setup").toggleClass("active");
                          }}
                        >
                          <div className="chat_right_top_setup h-100">
                            <IoEllipsisHorizontal className="chat_right_top_setup_open" />
                            <IoCloseOutline className="chat_right_top_setup_close" />
                          </div>
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  </div>

                  {arrItemsChat.active_chat && arrItemsChat.active_chat > 0 ? (
                    <MessageList
                      prop_active_chat={arrItemsChat.active_chat}
                      prop_new_message={notificationChat[arrItemsChat.active_chat]}
                    />
                  ) : (
                    false
                  )}

                  {arrItemsChat.active_chat && setupWindow && arrItemsChat.active_chat !== "1" ? (
                    <div className="chat_right_setup active">
                      <div className="chat_right_setup_logo">
                        <div className="chat_right_setup_logo_img">
                          {arrItemsChat.active_chat_info && arrItemsChat.active_chat_info.image ? (
                            <img src={arrItemsChat.active_chat_info.image} alt="logo" />
                          ) : (
                            <img src={avatar} alt="logo" />
                          )}
                        </div>
                        {arrItemsChat.user_list && arrItemsChat.user_list.length !== 2 ? (
                          <div className="chat_right_setup_logo_btn">
                            <form>
                              <input
                                id="btnFromAddLogoChat"
                                className="class_display_none"
                                type="file"
                                onChange={changeImageChat}
                              />
                              <label htmlFor="btnFromAddLogoChat">
                                <IoCameraReverseOutline />
                              </label>
                            </form>
                          </div>
                        ) : (
                          false
                        )}
                      </div>

                      <div className="chat_right_setup_name">
                        {arrItemsChat.active_chat_info && arrItemsChat.active_chat_info.name ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={arrItemsChat.active_chat_info.name}
                            fieldType="change_name"
                            propsIditem={arrItemsChat.active_chat}
                          />
                        ) : (
                          false
                        )}
                      </div>

                      <div className="chat_right_setup_users ">
                        <div className="chat_right_setup_users_title">Участники чата</div>
                        <div className="chat_right_setup_users_items">
                          {arrItemsChat && arrItemsChat.user_list
                            ? arrItemsChat.user_list.map((arritemschatuserlist, index) =>
                                arritemschatuserlist ? (
                                  <div className="chat_right_setup_users_item" key={index}>
                                    <div className="chat_right_setup_users_name">
                                      {arritemschatuserlist.value}
                                    </div>
                                    <div
                                      className="chat_right_setup_users_del"
                                      onClick={() => {
                                        if (window.confirm("Удалить?")) {
                                          getChat(
                                            arrItemsChat.active_chat,
                                            "user",
                                            "",
                                            arritemschatuserlist.id_user
                                          );
                                        }
                                      }}
                                    >
                                      <FiTrash />
                                    </div>
                                  </div>
                                ) : (
                                  false
                                )
                              )
                            : false}
                        </div>
                        <div className="btn_one chat_right_setup_users_add">
                          {userChat && userChat[0] && userChat[0].id ? (
                            <div className="list_item_user_chat">
                              {userChat.map((userchatvalue, index) =>
                                userchatvalue ? (
                                  <div key={index}>
                                    <div
                                      className="add_user_click"
                                      onClick={() => {
                                        if (window.confirm("Добавить?")) {
                                          getChat(arrItemsChat.active_chat, "user", "", userchatvalue.id);
                                          stateUserChat([]);
                                        }
                                      }}
                                    >
                                      {userchatvalue.value}
                                    </div>
                                  </div>
                                ) : (
                                  false
                                )
                              )}
                            </div>
                          ) : (
                            <div className="btn_one" onClick={() => chatUser()}>
                              Добавить участника
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </Col>
            ) : (
              false
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Chat;
