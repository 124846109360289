import { useState, useEffect } from "react";
import CrmContact from "./CrmContact";

const CrmCentralBlock = ({ data, tabs }) => {
  const [centralBlock, setCentralBlock] = useState([]);
  const [crmTab, setCrmTab] = useState("contact");

  useEffect(() => {
    setCentralBlock(data);
  }, [data]);

  return (
    <>
      {tabs ? (
        <div className="ticket-block-tab-button">
          {tabs.contact &&
          tabs.contact.status &&
          tabs.contact.status === "y" ? (
            <div
              className={`tab-contact tab-title-button ${
                crmTab === "contact" ? "active" : ""
              }`}
            >
              <div onClick={() => setCrmTab("contact")}>
                {tabs.contact.name ? tabs.contact.name : false}
              </div>
            </div>
          ) : (
            false
          )}

          {tabs.basket && tabs.basket.status && tabs.basket.status === "y" ? (
            <div
              className={`tab-basket tab-title-button ${
                crmTab === "basket" ? "active" : ""
              }`}
            >
              <div onClick={() => setCrmTab("basket")}>
                <div className="a-flex a-gap-5 a-center">
                  <div>{tabs.basket.name ? tabs.basket.name : false}</div>
                </div>
              </div>
            </div>
          ) : (
            false
          )}
          {tabs.history &&
          tabs.history.status &&
          tabs.history.status === "y" ? (
            <div
              className={`tab-history tab-title-button ${
                crmTab === "history" ? "active" : ""
              }`}
            >
              <div onClick={() => setCrmTab("history")}>
                {tabs.history.name ? tabs.history.name : false}
              </div>
            </div>
          ) : (
            false
          )}
          {tabs.ticket && tabs.ticket.status && tabs.ticket.status === "y" ? (
            <div
              className={`tab-ticket tab-title-button ${
                crmTab === "ticket" ? "active" : ""
              }`}
            >
              <div onClick={() => setCrmTab("ticket")}>
                {tabs.ticket.name ? tabs.ticket.name : false}
              </div>
            </div>
          ) : (
            false
          )}
        </div>
      ) : (
        false
      )}

      {crmTab ? (
        crmTab === "contact" ? (
          <div>
            <CrmContact centralBlock={centralBlock} />
          </div>
        ) : (
          false
        )
      ) : (
        false
      )}
    </>
  );
};
export default CrmCentralBlock;
