import { useState, useEffect } from "react";
import AppHeader from "./AppHeader";
import Menu from "./menu/Menu";
import Routing from "./Routing";
import { BrowserRouter } from "react-router-dom";
import Chat from "../unit/chat/Chat";
import Auth from "../auth/Auth";
import GetData from "components/unit/servicdesk/getdata/GetData";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoChatboxEllipsesOutline, IoMenuSharp } from "react-icons/io5";
import { GoHome } from "react-icons/go";
import { GrClose } from "react-icons/gr";

function Main() {
  const [tokenVerificationOut, setTokenVerificationOut] = useState(false);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenMobileChat, setIsOpenMobileChat] = useState(false);
  const [expand, setExpand] = useState(false);
  const [authType, setAuthType] = useState("auth");

  useEffect(() => {
    async function fetchData() {
      const tokenVerification = await checkToken();
      setTokenVerificationOut(tokenVerification);
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (window.screen.width <= 576) {
      setExpand("mobile");
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("registration")) {
      setAuthType("registration");
    } else {
      setAuthType("auth");
    }
  }, [authType]);

  async function checkToken() {
    let tokenVerificationIn = "N";
    const request_parameters = { module: "auth", block: "check" };
    const result = await GetData(request_parameters);
    tokenVerificationIn =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    return tokenVerificationIn;
  }

  //

  if (tokenVerificationOut) {
    if (tokenVerificationOut.check === "Y") {
      if (
        tokenVerificationOut.url &&
        tokenVerificationOut.url !== window.location.hostname &&
        window.location.hostname !== "localhost"
      ) {
        window.location.replace("https://" + tokenVerificationOut.url);
      }

      return (
        <>
          <div>
            <BrowserRouter>
              <AppHeader propsHeader={tokenVerificationOut} />
              <div className="menu-routing">
                <Menu
                  client={tokenVerificationOut.client}
                  module={tokenVerificationOut.module}
                  propsMobileMenu={isOpenMobileMenu}
                  propsSetMobileMenu={setIsOpenMobileMenu}
                  propsExpand={expand}
                />
                <Routing
                  client={tokenVerificationOut.client}
                  module={tokenVerificationOut.module}
                  paid={tokenVerificationOut.paid}
                />
              </div>
              {tokenVerificationOut &&
              tokenVerificationOut.client &&
              tokenVerificationOut.client === "inside" &&
              tokenVerificationOut.paid !== "N" ? (
                <Chat
                  client={tokenVerificationOut.client}
                  propsMobileChat={isOpenMobileChat}
                  propsSetMobileChat={setIsOpenMobileChat}
                  propsExpand={expand}
                />
              ) : (
                false
              )}
            </BrowserRouter>
            {expand === "mobile" ? (
              <div className="mobile-menu a-border-bg-t">
                <div className="mobile-menu-block">
                  <div className="item-mobile">
                    <div className="item-mobile-icon-text">
                      <a href="/">
                        <div className="item-mobile-icon">
                          <GoHome />
                        </div>
                        <div className="mobile-menu-text">Главная</div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="item-mobile"
                    onClick={() => setIsOpenMobileChat(!isOpenMobileChat)}
                  >
                    <div className="item-mobile-icon-text">
                      <div className="item-mobile-icon">
                        {isOpenMobileChat && isOpenMobileChat === true ? (
                          <GrClose />
                        ) : (
                          <IoChatboxEllipsesOutline />
                        )}
                      </div>
                      <div className="mobile-menu-text">Чат</div>
                    </div>
                  </div>
                  <div
                    className="item-mobile"
                    onClick={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
                  >
                    <div className="item-mobile-icon-text">
                      <div className="item-mobile-icon">
                        {isOpenMobileMenu && isOpenMobileMenu === true ? (
                          <GrClose />
                        ) : (
                          <IoMenuSharp />
                        )}
                      </div>
                      <div className="mobile-menu-text">Меню</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Auth propsType={authType} setPropsType={setAuthType} />
        </div>
      );
    }
  }
}

export default Main;
