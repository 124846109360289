import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetData from "../servicdesk/getdata/GetData";
import {
  ConstListUsers,
  telegramBotConst,
} from "../servicdesk/const/ConstTicket";
import FunctionChange from "components/general/FunctionChange";
import { IoCheckboxSharp, IoCameraReverseOutline } from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import avatar from "../../../image/png/avatar.png";
import { UserProfileConst } from "../servicdesk/const/ConstTicket";
import Message from "components/general/Message";
import PlugAccess from "components/general/PlugAccess";
import NotPaid from "components/general/NotPaid";
import NotFound from "components/general/NotFound";
import Unauthorized from "components/general/Unauthorized";
import "./user.css";

function User({ propsModule }) {
  const { id } = useParams();
  const [listUser, setListUser] = useState([]);
  const [message, setMessage] = useState([]);
  const [ticketStatusCode, setTicketStatusCode] = useState("");

  useEffect(() => {
    getUser(id);
  }, []);

  const getUser = async (id_item, edit_field, value, files) => {
    const request_parameters = {
      module: "settings",
      block: "users",
    };
    const request_attribute = {
      type_data: "user",
      id_item: id_item,
    };

    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await GetData(
      request_parameters,
      request_attribute,
      request_data,
      files
    );

    if (
      result &&
      result.request_attribute &&
      result.request_attribute.status_code
    ) {
      setTicketStatusCode(result.request_attribute.status_code);
    }

    const arrListUser =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setListUser(arrListUser);

    const arrMessage =
      result &&
      result.request_attribute &&
      result.request_attribute.status_request &&
      result.request_attribute.status_request.text
        ? result.request_attribute.status_request
        : [];

    setMessage(arrMessage);
  };

  function getValue(fieldType, value, id_item) {
    getUser(id_item, fieldType, value);
  }

  function acceptFiles(files) {
    const arrFileLogoUser = [files.target.files[0]];
    getUser(id, "image", "", arrFileLogoUser);
  }

  return (
    <>
      {message && message.text ? (
        <>
          <Message
            text={message.text}
            status={message.status}
            operation={message.operation}
          />
        </>
      ) : (
        false
      )}

      <div className="user_all_content">
        {ticketStatusCode === "404" ? (
          <>
            <NotFound />
          </>
        ) : ticketStatusCode === "403" ? (
          <PlugAccess />
        ) : ticketStatusCode === "402" ? (
          <NotPaid />
        ) : ticketStatusCode === "401" ? (
          <Unauthorized />
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="a-text-1 a-section-title">
                  {listUser && listUser.name && listUser.name.value ? (
                    <div>{listUser.name.value}</div>
                  ) : (
                    <div>{ConstListUsers.name.default}</div>
                  )}
                </div>
              </div>

              <div className="col-3 pb-4">
                <div className="user-main-left-block border_background a-height-100 p-4">
                  <div className="user_image">
                    <div className="user_image_logo a-relative">
                      {listUser && listUser.image && listUser.image.value ? (
                        <img src={listUser.image.value}></img>
                      ) : (
                        <img src={avatar} alt="logo" />
                      )}
                      <div>
                        <form>
                          <input
                            id="btnFromAddLogoUser"
                            className="class_display_none"
                            type="file"
                            onChange={acceptFiles}
                          />
                          <label
                            className="user_image_label"
                            htmlFor="btnFromAddLogoUser"
                          >
                            <IoCameraReverseOutline />
                          </label>
                        </form>
                      </div>
                    </div>
                  </div>

                  {listUser &&
                  listUser.last_activity &&
                  listUser.last_activity.value ? (
                    <div
                      className={`last_activity ${
                        listUser.last_activity.online == "y" ? "online" : ""
                      }`}
                    >
                      {listUser.last_activity.value}
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
              <div className="col-6 pb-4">
                <div className="user-main-midlle-block border_background a-height-100 p-4">
                  <div className="a-text-2 border_bg_b_c_three pb-2">
                    Основная информация
                  </div>

                  <div className="user-main-middle-info">
                    <div className="col-6">
                      <div className="user-main-item">
                        <div className="a-text-4">
                          {ConstListUsers.name.title}
                        </div>
                        {listUser && listUser.name && listUser.name.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              listUser.name.value ? listUser.name.value : ""
                            }
                            fieldType={listUser.name.field}
                            propsState={listUser.new}
                            propsIditem={listUser.id.value}
                          />
                        ) : (
                          ConstListUsers.name.default
                        )}
                      </div>

                      <div className="user-main-item">
                        <div className="a-text-4">
                          {ConstListUsers.department.title}
                        </div>
                        {listUser &&
                        listUser.department &&
                        listUser.department.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              listUser.department.value
                                ? listUser.department.value
                                : ""
                            }
                            fieldType={listUser.department.field}
                            propsIditem={listUser.id.value}
                            propsInput="select"
                            propsParamData={{
                              module: "settings",
                              block: "departments",
                              for: "lh",
                            }}
                          />
                        ) : (
                          ConstListUsers.department.default
                        )}
                      </div>
                      <div className="user-main-item">
                        <div className="a-text-4">
                          {ConstListUsers.position.title}
                        </div>
                        {listUser &&
                        listUser.position &&
                        listUser.position.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              listUser.position.value
                                ? listUser.position.value
                                : ""
                            }
                            fieldType={listUser.position.field}
                            propsIditem={listUser.id.value}
                          />
                        ) : (
                          ConstListUsers.position.default
                        )}
                      </div>

                      <div className="user-main-item">
                        <div className="">
                          <div className="a-text-4">
                            {ConstListUsers.birthday.title}
                          </div>
                          {listUser &&
                          listUser.birthday &&
                          listUser.birthday.value ? (
                            <FunctionChange
                              getValue={getValue}
                              propsValue={listUser.birthday.value}
                              fieldType={listUser.birthday.field}
                              propsIditem={listUser.id.value}
                              propsInput={"date"}
                            />
                          ) : (
                            ConstListUsers.birthday.default
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="user-main-item">
                        <div className="a-text-4">
                          {ConstListUsers.phone.title}
                        </div>
                        {listUser && listUser.phone && listUser.phone.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={listUser.phone.value}
                            fieldType={listUser.phone.field}
                            propsIditem={listUser.id.value}
                          />
                        ) : (
                          ConstListUsers.phone.default
                        )}
                      </div>
                      <div className="user-main-item">
                        <div className="a-text-4">
                          {ConstListUsers.mail.title}
                        </div>
                        {listUser && listUser.mail && listUser.mail.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              listUser.mail.value ? listUser.mail.value : ""
                            }
                            fieldType={listUser.mail.field}
                            propsIditem={listUser.id.value}
                          />
                        ) : (
                          ConstListUsers.mail.default
                        )}
                      </div>
                      <div className="user-main-item">
                        <div className="a-text-4">
                          {ConstListUsers.telegram.title}
                        </div>

                        {listUser &&
                        listUser.telegram &&
                        listUser.telegram.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              listUser.telegram.value
                                ? listUser.telegram.value
                                : ""
                            }
                            fieldType={listUser.telegram.field}
                            propsIditem={listUser.id.value}
                          />
                        ) : (
                          false
                        )}
                      </div>
                      <div className="user-main-item">
                        <div className="a-text-4">
                          {ConstListUsers.whatsapp.title}
                        </div>
                        {listUser &&
                        listUser.whatsapp &&
                        listUser.whatsapp.field ? (
                          <FunctionChange
                            getValue={getValue}
                            propsValue={listUser.whatsapp.value}
                            fieldType={listUser.whatsapp.field}
                            propsIditem={listUser.id.value}
                          />
                        ) : (
                          ConstListUsers.whatsapp.default
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 pb-4">
                <div className="user-main-right-block border_background a-height-100 p-4">
                  <div className="a-text-2 border_bg_b_c_three pb-2">
                    Уведомления
                  </div>
                  <div className="user-main-right-block-items">
                    {listUser.notifications
                      ? listUser.notifications.map(
                          (notificationsvalue, index) => (
                            <div
                              className="user-main-notification a-text-4"
                              key={index}
                            >
                              <div
                                className={
                                  notificationsvalue.active === "y" &&
                                  notificationsvalue.id &&
                                  notificationsvalue.id === 1
                                    ? "user-notification-checkbox "
                                    : notificationsvalue.active === "y" &&
                                      notificationsvalue.id &&
                                      notificationsvalue.id !== 1
                                    ? "user-notification-checkbox active "
                                    : "user-notification-checkbox active "
                                }
                                onClick={() => {
                                  if (
                                    notificationsvalue.id &&
                                    notificationsvalue.id > 1
                                  ) {
                                    getUser(
                                      listUser.id.value,
                                      notificationsvalue.field,
                                      notificationsvalue.id
                                    );
                                  }
                                }}
                              >
                                {notificationsvalue.active === "y" ? (
                                  <IoCheckboxSharp />
                                ) : (
                                  <MdCheckBoxOutlineBlank />
                                )}
                              </div>
                              <div className="user-main-item-notification">
                                {notificationsvalue.value
                                  ? notificationsvalue.value
                                  : false}
                              </div>
                            </div>
                          )
                        )
                      : false}
                  </div>

                  <div className="a-text-2 border_bg_b_c_three pb-2">
                    Телеграм бот
                  </div>

                  {propsModule &&
                  propsModule.telegram &&
                  propsModule.telegram === "y" ? (
                    <div>
                      <div className="user-main-connect_telegram_block">
                        {listUser &&
                        listUser.connect_telegram &&
                        listUser.connect_telegram.value === "y" ? (
                          <div className="user-main-notification a-text-4">
                            <div className="user-notification-checkbox active">
                              <IoCheckboxSharp />
                            </div>
                            <div className="user-main-item-notification">
                              {telegramBotConst.telegram.on}
                            </div>
                          </div>
                        ) : listUser &&
                          listUser.connect_telegram &&
                          listUser.connect_telegram.code_request ? (
                          listUser.connect_telegram.code_request === "y" ? (
                            <div className="user-main-connect_telegram">
                              <div
                                className=" a-btn-block mt-2 "
                                onClick={() => getUser(id, "connect_telegram")}
                              >
                                {telegramBotConst.telegram.off}
                              </div>
                            </div>
                          ) : (
                            <div className="user-main-notification a-text-4">
                              <div className="user-notification-checkbox ">
                                <MdCheckBoxOutlineBlank />
                              </div>
                              <div className="user-main-item-notification">
                                {telegramBotConst.telegram.no_on}
                              </div>
                            </div>
                          )
                        ) : (
                          false
                        )}
                      </div>

                      <div className="">
                        {listUser &&
                        listUser.connect_telegram &&
                        listUser.connect_telegram.code &&
                        listUser.connect_telegram.code.length > 7 ? (
                          listUser &&
                          listUser.connect_telegram &&
                          listUser.connect_telegram.value === "y" ? (
                            <div>Код: {listUser.connect_telegram.code}</div>
                          ) : (
                            <div>
                              Для подключения Телеграм бота{" "}
                              <a
                                href="https://t.me/aritin_bot"
                                className="a-text--c-three"
                                target="_blank"
                              >
                                @aritin_bot
                              </a>
                              , используйте код:{" "}
                              <b>{listUser.connect_telegram.code}</b>
                            </div>
                          )
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="p-2">
                      Для подключения Телеграм бота подключите модуль в блоке
                      оплата
                    </div>
                  )}
                </div>
              </div>

              <div className="col-12 pb-4">
                <div className="border_background a-height-100 p-4">
                  <div className=" ">
                    <div className="a-text-2 border_bg_b_c_three pb-2">
                      Роли
                    </div>
                    <div className="user-roles-block-items a-flex a-gap-20">
                      {listUser.roles
                        ? listUser.roles.map((listuservalue, index) => (
                            <div
                              className="user-roles-item a-flex a-gap-10"
                              key={index}
                            >
                              <div
                                className={`user_role_checkbox ${
                                  listuservalue.active === "y" ? "active" : ""
                                }`}
                                onClick={() =>
                                  getUser(
                                    listUser.id.value,
                                    listuservalue.field,
                                    listuservalue.id
                                  )
                                }
                              >
                                {listuservalue.active === "y" ? (
                                  <IoCheckboxSharp />
                                ) : (
                                  <MdCheckBoxOutlineBlank />
                                )}
                              </div>
                              <div className="a-text-4 user-roles-item-value">
                                {listuservalue.value
                                  ? listuservalue.value
                                  : false}
                              </div>
                            </div>
                          ))
                        : false}
                    </div>
                  </div>
                </div>
              </div>

              {listUser.tokens &&
              Array.isArray(listUser.tokens) &&
              listUser.tokens.length > 0 ? (
                <div className="col-12">
                  <div className="border_background a-height-100 p-4">
                    <div className="a-text-2 border_bg_b_c_three pb-2">
                      Активные сессии
                    </div>
                    <div>
                      {UserProfileConst ? (
                        <div className="grid-userprofileconst-token a-text-4 p-2">
                          <div>{UserProfileConst.device.title}</div>
                          <div>{UserProfileConst.date.title}</div>
                          <div>{UserProfileConst.ip.title}</div>
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                    <div>
                      {listUser.tokens
                        ? listUser.tokens.map((listusertokensvalue, index) =>
                            listusertokensvalue ? (
                              <div
                                key={index}
                                className={`grid-userprofileconst-token a-text-4-400 a-border-bg-b--c-nine-light p-2 ${
                                  listusertokensvalue.active === "y"
                                    ? "сurrent-device"
                                    : "other-device"
                                }`}
                              >
                                <div>
                                  {listusertokensvalue.active === "y" ? (
                                    <div className="">Текущее устройство</div>
                                  ) : (
                                    <div>Другое устройство</div>
                                  )}
                                </div>
                                <div>{listusertokensvalue.last_activity}</div>
                                <div>{listusertokensvalue.value}</div>
                                <div
                                  className="a-btn-block"
                                  onClick={() =>
                                    getUser(
                                      id,
                                      "log_out",
                                      listusertokensvalue.id
                                    )
                                  }
                                >
                                  Выйти
                                </div>
                              </div>
                            ) : (
                              false
                            )
                          )
                        : false}
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default User;
