import { useState, useEffect } from "react";
import "./crm_topbar.css";
import AritinEditor from "components/general/AritinEditor";
import { useParams } from "react-router-dom";
import GetData from "../servicdesk/getdata/GetData";
import FunctionChange from "components/general/FunctionChange";

const CrmTopbar = ({ data }) => {
  const [topBar, setTopBar] = useState([]);
  const [changeFieldMain, setChangeFieldMain] = useState(false);
  const { id, type } = useParams();

  useEffect(() => {
    setTopBar(data);
  }, [data]);

  const getCrmTopbar = async (edit_field, value, id_item) => {
    const request_parameters = {
      module: "crm",
      block: type,
    };
    const request_attribute = {
      area: "topbar",
      id: id,
      action: "edit_topbar",
      id_item: id_item,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await GetData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrTopbar =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setTopBar(arrTopbar);
  };

  function getValue(edit_field, value, id_item) {
    getCrmTopbar(edit_field, value, id_item);
  }

  return (
    <>
      {topBar ? (
        <div className="crm-topbar-block">
          <div className="crm-topbar-title">
            <FunctionChange
              getValue={getValue}
              propsValue={
                topBar.title &&
                topBar.title.value &&
                topBar.title.value.length > 0
                  ? topBar.title.value
                  : ""
              }
              fieldType={
                topBar.title &&
                topBar.title.field &&
                topBar.title.field.length > 0
                  ? topBar.title.field
                  : ""
              }
              propsIditem={id}
            />
          </div>

          {topBar.id && topBar.id.value ? (
            <div className="crm-topbar-lead-number">
              Лид № {topBar.id.value}
            </div>
          ) : (
            false
          )}
          <div>
            {" "}
            {topBar.status_management ? (
              <div className="crm-topbar-lead-status-management">
                {topBar.status_management}
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
};
export default CrmTopbar;
