import React, { useState, useEffect } from "react";
import GetData from "../servicdesk/getdata/GetData";
import { FiTrash } from "react-icons/fi";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { IoIosAddCircleOutline } from "react-icons/io";
import FunctionChange from "components/general/FunctionChange";
import $ from "jquery";
import "./structure.css";

function Structure() {
  const [listStructure, setListStructure] = useState([]);
  const [changeDepartment, setChangeDepartment] = useState(null);
  const [company, setCompany] = useState([]);

  const getStructure = async (fieldType, value, idItem, action) => {
    const request_parameters = {
      module: "settings",
      block: "departments",
    };
    const request_attribute = {
      id_item: idItem,
      action: action,
    };
    const request_data = {
      edit_field: fieldType,
      value: value,
    };

    const result = await GetData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrListStructure =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setListStructure(arrListStructure);
    setChangeDepartment(false);
    setCompany(result.request_data.company);
  };

  useEffect(() => {
    getStructure();
  }, []);

  useEffect(() => {
    if (changeDepartment) {
      $(".listdepartment").focus();
      document.addEventListener("click", handleOutsideClick);
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [changeDepartment]);

  const handleOutsideClick = (event) => {
    if (
      $(event.target).hasClass("listdepartment") ||
      $(event.target).hasClass("listdepartment-selector-value") ||
      $(event.target).hasClass("structure-change-the-section") ||
      $(event.target).hasClass("structure-change-the-section-svg")
    ) {
    } else {
      setChangeDepartment(false);
    }
  };

  function getValueStructureInfo(fieldType, value, id_item) {
    getStructure(fieldType, value, id_item);
  }

  function listDepartment(
    departmentId,
    parentDepartmentId,
    departmentChildNum
  ) {
    if (
      changeDepartment === departmentId &&
      (departmentChildNum < 1 || !departmentChildNum)
    ) {
      return (
        <div className="listdepartment">
          {parentDepartmentId !== "0" ? (
            <div
              className="listdepartment-selector-value"
              onClick={() =>
                getStructure("parent_department", "0", changeDepartment)
              }
            >
              Корневой отдел
            </div>
          ) : (
            false
          )}
          {listStructure
            .filter(
              (item) =>
                item.parent_department.value === "0" &&
                item.id.value !== changeDepartment
            )
            .map((itemparentdepartmentvalue, index) => (
              <div
                className="listdepartment-selector-value"
                key={index}
                onClick={() =>
                  getStructure(
                    "parent_department",
                    itemparentdepartmentvalue.id.value,
                    changeDepartment
                  )
                }
              >
                {itemparentdepartmentvalue.name.value}
              </div>
            ))}
        </div>
      );
    } else {
      return (
        <div
          className="structure-change-the-section a-pointer"
          onClick={() => {
            setChangeDepartment(departmentId);
          }}
          title="Изменить раздел"
        >
          <LiaExchangeAltSolid className="structure-change-the-section-svg" />
        </div>
      );
    }
  }

  const renderDepartment = (department) => {
    return (
      <div className="structure-level-two-items">
        {listStructure
          .filter(
            (item) => item.parent_department.value === department.id.value
          )
          .map((child, index) => (
            <div key={index} className="structure-level-two-item">
              <div className="a-radius-5 listdepartment-child-block">
                {listDepartment(child.id.value, child.parent_department.value)}

                <div
                  className="active_delete_child a-pointer"
                  onClick={() => getStructure("", "", child.id.value, "delete")}
                >
                  <FiTrash />
                </div>

                <div className="p-2 a-text-3 a-border-bg-b--c-three a-radius-5-5">
                  <div className="structure-department-name">
                    <FunctionChange
                      getValue={getValueStructureInfo}
                      propsValue={
                        child && child.name && child.name.value
                          ? child.name.value
                          : ""
                      }
                      propsIditem={
                        child && child.id && child.id.value
                          ? child.id.value
                          : ""
                      }
                      fieldType="name"
                    />
                  </div>
                </div>
                {/* <div className="structure-department-boss p-3 a-text-5">
                  Руководитель
                  </div>*/}

                <div className="structure-users p-3">
                  <div className="a-text-5 pb-2">Сотрудники</div>
                  {child.users && child.users.length > 0 ? (
                    child.users.map((user, index) => (
                      <div key={index} className="structure-user">
                        {user.name}
                      </div>
                    ))
                  ) : (
                    <div className="a-text--c-six a-text-5-400">
                      Нет сотрудников
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <>
      <div className="structure-block">
        <div className="container">
          <div className="a-text-1 a-section-title">
            Стурктура {company && company.name ? company.name : "компании"}
          </div>

          <div className="structure a-border-bg">
            <div className="a-flex-center p-3">
              <div className="a-radius-5 listdepartment-main-block">
                <div className="p-2 a-text-3 a-border-bg-b--c-three a-radius-5-5">
                  <div className="structure-department-name-main">
                    <div className="">
                      {company && company.name
                        ? company.name
                        : "Название компании"}
                    </div>
                  </div>
                </div>

                <div
                  className="structure-add-section-main a-pointer"
                  onClick={() => getStructure("", "", "", "add")}
                >
                  <IoIosAddCircleOutline />
                </div>
                <div className="a-flex-center p-2 a-text-5">
                  Структура компании
                </div>
              </div>
            </div>
            <div className="structure-level-one-items a-flex a-gap-20 p-3">
              {listStructure.map((department, index) => {
                if (department.parent_department.value === "0") {
                  return (
                    <div key={index} className="structure-level-one-item ">
                      <div className="a-radius-5 listdepartment-parent-block">
                        {listDepartment(
                          department.id.value,
                          department.parent_department.value,
                          department.child_num
                        )}

                        <div
                          className="structure-add-section a-pointer"
                          onClick={() =>
                            getStructure("", "", department.id.value, "add")
                          }
                        >
                          <IoIosAddCircleOutline />
                        </div>

                        <div className="structure-delete-section">
                          {department.child_num > 0 ? (
                            <div className="not_active_delete">
                              <FiTrash />
                            </div>
                          ) : (
                            <div
                              className="active_delete a-pointer"
                              onClick={() =>
                                getStructure(
                                  "",
                                  "",
                                  department.id.value,
                                  "delete"
                                )
                              }
                            >
                              <FiTrash />
                            </div>
                          )}
                        </div>
                        <div className="p-2 a-text-3 a-border-bg-b--c-three a-radius-5-5">
                          <div className="structure-department-name">
                            <FunctionChange
                              getValue={getValueStructureInfo}
                              propsValue={
                                department &&
                                department.name &&
                                department.name.value
                                  ? department.name.value
                                  : ""
                              }
                              propsIditem={
                                department &&
                                department.id &&
                                department.id.value
                                  ? department.id.value
                                  : ""
                              }
                              fieldType="name"
                            />
                          </div>
                        </div>
                        {/* <div className="structure-department-boss p-3 a-text-5">
                      Руководитель
                    </div>*/}

                        <div className="structure-users p-3">
                          <div className="a-text-5 pb-2">Сотрудники</div>
                          {department.users && department.users.length > 0 ? (
                            department.users.map((user, index) => (
                              <div key={index} className="structure-user">
                                {user.name}
                              </div>
                            ))
                          ) : (
                            <div className="a-text--c-six a-text-5-400">
                              Нет сотрудников
                            </div>
                          )}
                        </div>
                      </div>
                      {renderDepartment(department)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Structure;
