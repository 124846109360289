import { useState, useEffect, useRef, Fragment } from "react";
import $ from "jquery";
import avatar from "../../../image/png/avatar.png";
import getData from "../servicdesk/getdata/GetData";
import GallerySlider from "components/general/GallerySlider";
import {
  IoDocumentOutline,
  IoAttachOutline,
  IoSendSharp,
  IoClose,
  IoArrowUndoOutline,
  IoCheckmarkDoneOutline,
  IoAlertCircleOutline,
} from "react-icons/io5";
import TextareaAutosize from "react-textarea-autosize";
import ContentEditable from "react-contenteditable";
import { FiTrash } from "react-icons/fi";
import { symbolEmojis } from "../../general/Emojis";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import "./chat.css";

//
// START COMPONENT
//

function MessageList({ prop_active_chat, prop_new_message }) {
  //
  // STATE
  //
  const [currentScrollBottom, setCurrentScrollBottom] = useState(0);
  const chatContentRef = useRef(null); // Ссылка на элемент <div>
  const [active_chat, setActive_chat] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [lastViewedMessage, setLastViewedMessage] = useState(0);
  const [message_list, setMessage_list] = useState([]);
  const [selectedFileChat, setselectedFileChat] = useState([]);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [galleryChatImages, setGalleryChatImages] = useState([]);
  const [galleryChatImageIndex, setGalleryChatImageIndex] = useState("");
  const [activeMessageForAnswer, setActiveMessageForAnswer] = useState(null);
  const [viewedList, setViewedList] = useState([]);

  const emojiSymbolsArr = symbolEmojis.map((emoji) => emoji.symbol);
  const emojiString = emojiSymbolsArr.join("|");
  const emojiRegex = new RegExp(emojiString);
  const emojiRegex2 = new RegExp(`^\\s*(${emojiString})\\s*$`);

  const emojiSymbolsArrSplit = symbolEmojis.map((emoji) => `(${emoji.symbol})`); // Добавляем скобки при создании массива
  const emojiStringSplit = emojiSymbolsArrSplit.join("|");
  const emojisSplit = new RegExp(emojiStringSplit);

  const deleteFileFromState = (index) => {
    const newArray = [...selectedFileChat]; // Создаем копию массива
    newArray.splice(index, 1); // Удаляем элемент по индексу
    setselectedFileChat(newArray); // Обновляем состояние с новым массивом
  };

  // END STATE

  //
  // USE EFFECT
  //
  useEffect(() => {
    // смена чата
    setMessage_list([]);
    setActive_chat(prop_active_chat); // обновляем ID активного чата
    setPageNumber(1); // устанавливаем первую страницу
    setCurrentScrollBottom(0); // устанавливаем прокрутку вниз
    setActiveMessageForAnswer(null); // убираем ответы на сообщения
    setselectedFileChat([]); // очищаем файлы в буфере
    setEditMessage(false);
    setShowMore(false);
  }, [prop_active_chat]);

  useEffect(() => {
    // новое сообщение

    if (prop_new_message > 0 && active_chat > 0) {
      setEditMessage(false);
      let scrollBottom = 0;
      if (
        chatContentRef &&
        chatContentRef.current &&
        chatContentRef.current.scrollHeight
      ) {
        scrollBottom =
          chatContentRef.current.scrollHeight -
          chatContentRef.current.scrollTop;
      }
      setCurrentScrollBottom(scrollBottom);
      getChat(active_chat);
    }
  }, [prop_new_message]);

  useEffect(() => {
    if (active_chat > 0) {
      getChat(active_chat);
    }
  }, [active_chat]);

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, []);

  useEffect(() => {
    if (chatContentRef.current) {
      // Проверка, что ref доступен
      const scrollBottom =
        chatContentRef.current.scrollHeight - chatContentRef.current.scrollTop;
      if (scrollBottom > currentScrollBottom) {
        chatContentRef.current.scrollTop =
          chatContentRef.current.scrollHeight - currentScrollBottom;
      }
      setCurrentScrollBottom(scrollBottom);
    }
  }, [message_list]);

  // USE EFFECT

  //
  // FUNCTION
  //

  const getChat = async (id_chat, action, id_item, value, page_number) => {
    let current_page_number = pageNumber;
    if (page_number === "more") {
      current_page_number++;
      setPageNumber(current_page_number);
    }

    const request_parameters = {
      module: "chat",
      block: "message_list",
    };

    if (activeMessageForAnswer && action === "add_message") {
      id_item = activeMessageForAnswer;
      setPageNumber(1); // устанавливаем первую страницу
      setCurrentScrollBottom(0); // устанавливаем прокрутку вниз
      setActiveMessageForAnswer(null); // убираем ответы на сообщения
      setselectedFileChat([]); // очищаем файлы в буфере
      current_page_number = 1;
    } else if (action === "add_message") {
      setPageNumber(1); // устанавливаем первую страницу
      setCurrentScrollBottom(0); // устанавливаем прокрутку вниз
      setActiveMessageForAnswer(null); // убираем ответы на сообщения
      setselectedFileChat([]); // очищаем файлы в буфере
      current_page_number = 1;
    }

    const request_attribute = {
      id_chat: prop_active_chat,
      action: action,
      id_item: id_item,
      page_number: current_page_number,
    };

    const request_data = {
      value: value,
    };

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data,
      selectedFileChat
    );

    const arrChat =
      result &&
      result.request_data &&
      result.request_data.items.message_list &&
      result.request_data.items.active_chat
        ? result.request_data.items.message_list
        : [];

    const lastViewedMessageID =
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.last_viewed_message
        ? result.request_data.items.last_viewed_message
        : 0;

    setLastViewedMessage(lastViewedMessageID);

    const show_more_result =
      result &&
      result.request_data &&
      result.request_data.items &&
      result.request_data.items.show_more
        ? result.request_data.items.show_more
        : false;
    setShowMore(show_more_result);

    if (page_number === "more") {
      requestAnimationFrame(() => {
        setMessage_list({ ...message_list, ...arrChat });
      });
    } else {
      setMessage_list(arrChat);
    }

    if (id_chat > 0) {
      $("#chat_textarea_comment").focus();
    }
  };

  function addOnChange(changeFile) {
    setselectedFileChat((prevselectedFileChat) => [
      ...prevselectedFileChat,
      changeFile.target.files[0],
    ]);
  }

  const addEmoji = (emoji) => {
    let textareaValue = $("#chat_textarea_comment").val();
    textareaValue = textareaValue + emoji;
    $("#chat_textarea_comment").val(textareaValue);
  };

  function handlePaste(pasteEvent) {
    const item = pasteEvent.clipboardData.items[0];
    if (item.type.indexOf("image") === 0) {
      var blob = item.getAsFile();

      setselectedFileChat((prevselectedFileChat) => [
        ...prevselectedFileChat,
        blob,
      ]);
    }
  }

  const handleKeyPress = (event) => {
    const textarea = $("#chat_textarea_comment");
    textarea.css("height", "auto"); // Сброс высоты
    let textareaScrollHeight = textarea[0].scrollHeight;
    if (textareaScrollHeight > 160) {
      textareaScrollHeight = 160;
    }

    // Увеличиваем высоту textarea
    textarea.css("height", textareaScrollHeight + "px"); // Установка новой высоты
    const chatRightBottom = $(".chat_right_bottom ");
    let heightChatRightBottom = textareaScrollHeight + 28;
    chatRightBottom.css("height", heightChatRightBottom + "px"); // Установка новой высоты

    // Проверка нажатия клавиши Enter без Shift
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault(); // Предотвращаем стандартное поведение Enter
      var textareaValue = textarea.val();
      var textareaValue_after_check = textareaValue.replace(/\n/g, "");

      // Проверка на наличие текста
      if (textareaValue.length > 0 && textareaValue_after_check.length > 0) {
        getChat(active_chat, "add_message", "", textareaValue);
        textarea.val(""); // Очищаем textarea
        textarea.css("height", "auto"); // Сбрасываем высоту после отправки сообщения
        chatRightBottom.css("height", "auto"); // Сбрасываем высоту после отправки сообщения
      }
    }
  };

  //
  // HTML
  //

  return (
    <>
      <div
        className={`chat_right_middle ${
          active_chat && active_chat === "1" ? "full_height" : ""
        } `}
      >
        <div className="chat_content">
          {message_list && active_chat ? (
            <>
              <div className="reter">
                <GallerySlider
                  galleryImages={galleryChatImages}
                  galleryImageIndex={galleryChatImageIndex}
                  setGalleryImageIndex={setGalleryChatImageIndex}
                />
              </div>
              <div
                ref={chatContentRef}
                id="chat_content_items_height"
                className="chat_content_items_height"
              >
                {showMore ? (
                  <div
                    className="chat_download_past_message"
                    onClick={() => {
                      getChat(active_chat, "", "", "", "more");

                      const scrollBottom =
                        chatContentRef.current.scrollHeight -
                        chatContentRef.current.scrollTop;

                      setCurrentScrollBottom(scrollBottom);
                    }}
                  >
                    Загрузить предыдущие сообщения
                  </div>
                ) : (
                  false
                )}
                <>
                  {Object.keys(message_list).map(
                    (arritemschatmessage2, index) => {
                      const arritemschatmessage =
                        message_list[arritemschatmessage2];
                      if (arritemschatmessage) {
                        return (
                          <Fragment key={index}>
                            <div
                              className={`chat_message_item ${
                                arritemschatmessage.own === "y"
                                  ? "my_comment"
                                  : ""
                              } `}
                            >
                              <a name={"m" + arritemschatmessage2}></a>
                              {arritemschatmessage.own !== "y" ? (
                                <div className="chat_message_item_avatar">
                                  {arritemschatmessage.user &&
                                  arritemschatmessage.user.image ? (
                                    <img
                                      src={arritemschatmessage.user.image}
                                      alt="logo"
                                    />
                                  ) : (
                                    <img src={avatar} alt="logo" />
                                  )}
                                </div>
                              ) : (
                                false
                              )}

                              <div
                                className={`chat_message_item_block 
                    ${
                      arritemschatmessage.new === "y"
                        ? "viewed_new_comment"
                        : ""
                    }
                    ${
                      arritemschatmessage.message.delete ? "delete_comment" : ""
                    }
                        


                    
                    `}
                              >
                                {arritemschatmessage.user &&
                                arritemschatmessage.user.value &&
                                arritemschatmessage.own !== "y" ? (
                                  <div className="chat_message_item_user">
                                    {arritemschatmessage.user.value}

                                    {active_chat && active_chat !== "1" ? (
                                      <div
                                        className="chat_message_item_answer_btn"
                                        onClick={() => {
                                          setActiveMessageForAnswer(
                                            arritemschatmessage2
                                          );
                                          $("#chat_textarea_comment").focus();
                                        }}
                                      >
                                        Ответить
                                      </div>
                                    ) : (
                                      false
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className="chat_message_item_answer_btn"
                                    onClick={() => {
                                      setActiveMessageForAnswer(
                                        arritemschatmessage2
                                      );
                                      $("#chat_textarea_comment").focus();
                                    }}
                                  >
                                    <IoArrowUndoOutline />
                                  </div>
                                )}

                                {arritemschatmessage &&
                                arritemschatmessage.files &&
                                arritemschatmessage.files.image ? (
                                  <div className="chat_message_item_files">
                                    {arritemschatmessage.files.image.map(
                                      (filesvalue, index) =>
                                        filesvalue && filesvalue.url ? (
                                          <div
                                            key={index}
                                            className="chat_message_item_file"
                                          >
                                            <a>
                                              <img
                                                onClick={() => {
                                                  setGalleryChatImages(
                                                    arritemschatmessage.files
                                                      .image
                                                  );
                                                  setGalleryChatImageIndex(
                                                    index
                                                  );
                                                }}
                                                src={filesvalue.url}
                                                alt="file"
                                              />
                                            </a>
                                          </div>
                                        ) : (
                                          false
                                        )
                                    )}
                                  </div>
                                ) : (
                                  false
                                )}

                                {arritemschatmessage &&
                                arritemschatmessage.files &&
                                arritemschatmessage.files.other ? (
                                  <div className="chat_message_item_files_other">
                                    {arritemschatmessage.files.other.map(
                                      (filesvalue, index) =>
                                        filesvalue && filesvalue.url ? (
                                          <div
                                            key={index}
                                            className="chat_message_item_files_other_item"
                                          >
                                            <div className="chat_message_item_files_other_name">
                                              <a
                                                href={filesvalue.url}
                                                target="_blank"
                                              >
                                                {filesvalue.name}
                                              </a>
                                            </div>
                                            <div className="chat_message_item_files_other_icon">
                                              <a
                                                href={filesvalue.url}
                                                target="_blank"
                                              >
                                                <IoDocumentOutline />
                                              </a>
                                            </div>
                                            <div className="chat_message_item_files_other_size">
                                              {filesvalue.size}
                                            </div>
                                          </div>
                                        ) : (
                                          false
                                        )
                                    )}
                                  </div>
                                ) : (
                                  false
                                )}

                                {arritemschatmessage.message &&
                                arritemschatmessage.message.value ? (
                                  <div className="chat_message_item_text a-link">
                                    {arritemschatmessage.answer &&
                                    arritemschatmessage.answer > 0 ? (
                                      <a
                                        href={"#m" + arritemschatmessage.answer}
                                      >
                                        <div className="chat_message_item_answer_area">
                                          <div className="chat_message_item_answer_user">
                                            {message_list[
                                              arritemschatmessage.answer
                                            ] &&
                                            message_list[
                                              arritemschatmessage.answer
                                            ].user &&
                                            message_list[
                                              arritemschatmessage.answer
                                            ].user.value
                                              ? message_list[
                                                  arritemschatmessage.answer
                                                ].user.value
                                              : false}
                                          </div>
                                          <div className="chat_message_item_answer_message">
                                            {message_list[
                                              arritemschatmessage.answer
                                            ] &&
                                            message_list[
                                              arritemschatmessage.answer
                                            ].message &&
                                            message_list[
                                              arritemschatmessage.answer
                                            ].message.value
                                              ? message_list[
                                                  arritemschatmessage.answer
                                                ].message.value
                                              : false}
                                          </div>
                                        </div>
                                      </a>
                                    ) : (
                                      false
                                    )}

                                    {editMessage &&
                                    editMessage === arritemschatmessage.id &&
                                    arritemschatmessage.message.access &&
                                    arritemschatmessage.message.access ===
                                      "change" ? (
                                      <div className="chat-change-message-textareaautosize-block">
                                        <TextareaAutosize
                                          defaultValue={
                                            arritemschatmessage.message.value
                                          }
                                          id="editMessageValue"
                                        />

                                        <div className="edit-delete-message-block">
                                          <div
                                            className="edit-message"
                                            onClick={() => {
                                              var textMessageValue =
                                                $("#editMessageValue").val();
                                              getChat(
                                                active_chat,
                                                "change_message",
                                                arritemschatmessage.id,
                                                textMessageValue
                                              );
                                              $("#editMessageValue").val("");
                                              setEditMessage(false);
                                            }}
                                          >
                                            Сохранить{" "}
                                          </div>
                                          <div
                                            className="edit-message"
                                            onClick={() =>
                                              setEditMessage(false)
                                            }
                                          >
                                            Отменить
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <pre>
                                        {active_chat && active_chat === "1" ? (
                                          arritemschatmessage.message.value
                                            .split(/#(\d+)#/)
                                            .map((part, index) => {
                                              if (!isNaN(part)) {
                                                return (
                                                  <a
                                                    key={index}
                                                    href={"/task/" + part + "/"}
                                                    target="_blank"
                                                  >
                                                    {part}
                                                  </a>
                                                );
                                              }
                                              return (
                                                <span key={index}>{part}</span>
                                              );
                                            })
                                        ) : arritemschatmessage.message.value.match(
                                            emojiRegex2
                                          ) ? (
                                          <span className="big_emoji">
                                            {arritemschatmessage.message.value}
                                          </span>
                                        ) : (
                                          arritemschatmessage.message.value
                                            .split(/(https?:\/\/[^\s]+)/g)
                                            .map((part, index) => {
                                              if (
                                                part.match(/https?:\/\/[^\s]+/)
                                              ) {
                                                return (
                                                  <a
                                                    key={index}
                                                    href={part}
                                                    target="_blank"
                                                  >
                                                    {part}
                                                  </a>
                                                );
                                              }

                                              const parts =
                                                part.split(emojisSplit);

                                              return parts.map((p, i) => {
                                                if (emojiRegex.test(p)) {
                                                  return (
                                                    <span
                                                      key={index + i}
                                                      className="small_emoji"
                                                    >
                                                      {p}
                                                    </span>
                                                  );
                                                } else {
                                                  return (
                                                    <span key={index + i}>
                                                      {p}
                                                    </span>
                                                  );
                                                }
                                              });
                                            })
                                        )}
                                      </pre>
                                    )}

                                    {arritemschatmessage.message.edit &&
                                    editMessage !== arritemschatmessage.id ? (
                                      <div>
                                        <div className="edit-message-text">
                                          Изменено
                                        </div>
                                      </div>
                                    ) : (
                                      false
                                    )}

                                    {active_chat !== "1" &&
                                    !arritemschatmessage.message.delete &&
                                    !editMessage ? (
                                      arritemschatmessage.message.access &&
                                      arritemschatmessage.message.access ===
                                        "change" ? (
                                        <div className="edit-delete-message-block">
                                          <div
                                            className="edit-message"
                                            onClick={() =>
                                              setEditMessage(
                                                arritemschatmessage.id
                                              )
                                            }
                                          >
                                            Редактировать
                                          </div>

                                          <div
                                            className="delete-message"
                                            onClick={() =>
                                              getChat(
                                                active_chat,
                                                "delete_message",
                                                arritemschatmessage.id
                                              )
                                            }
                                          >
                                            Удалить
                                          </div>
                                        </div>
                                      ) : (
                                        false
                                      )
                                    ) : (
                                      false
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div
                                className={`chat_message_item_date ${
                                  active_chat && active_chat === "1"
                                    ? "lite_info"
                                    : ""
                                } `}
                              >
                                {arritemschatmessage.date &&
                                arritemschatmessage.date.value
                                  ? arritemschatmessage.date.value
                                  : false}
                                <div
                                  className={`iocheckmarkdoneoutline ${
                                    arritemschatmessage.viewed &&
                                    (!arritemschatmessage.viewed.users_n ||
                                      arritemschatmessage.viewed.users_n
                                        .length === 0)
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <IoCheckmarkDoneOutline className="iocheckmarkdoneoutline_svg" />

                                  {viewedList &&
                                  arritemschatmessage2 + "d" === viewedList ? (
                                    <IoAlertCircleOutline
                                      className="ioalertcircleoutline_svg 1"
                                      onClick={() => setViewedList()}
                                    />
                                  ) : (
                                    <>
                                      {active_chat !== "1" ? (
                                        <IoAlertCircleOutline
                                          className="ioalertcircleoutline_svg"
                                          onClick={() =>
                                            setViewedList(
                                              arritemschatmessage2 + "d"
                                            )
                                          }
                                        />
                                      ) : (
                                        <IoAlertCircleOutline className="ioalertcircleoutline_svg" />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              {viewedList &&
                              arritemschatmessage2 + "d" === viewedList ? (
                                <div className="message-viewed-block">
                                  {arritemschatmessage.viewed &&
                                  (arritemschatmessage.viewed.users_y ||
                                    arritemschatmessage.viewed.users_n) ? (
                                    <div className="message-viewed border_background">
                                      {arritemschatmessage.viewed.users_y
                                        ? arritemschatmessage.viewed.users_y.map(
                                            (viewedusersyvalue, index) =>
                                              viewedusersyvalue ? (
                                                <div
                                                  key={index}
                                                  className="message-viewed-user"
                                                >
                                                  <div>{viewedusersyvalue}</div>
                                                  <div>
                                                    <IoCheckmarkDoneOutline />
                                                  </div>
                                                </div>
                                              ) : (
                                                false
                                              )
                                          )
                                        : false}

                                      {arritemschatmessage.viewed.users_n
                                        ? arritemschatmessage.viewed.users_n.map(
                                            (viewedusersnvalue, index) =>
                                              viewedusersnvalue ? (
                                                <div
                                                  key={index}
                                                  className="message-notviewed-user"
                                                >
                                                  <div>{viewedusersnvalue}</div>
                                                  <div>
                                                    <IoCheckmarkDoneOutline />
                                                  </div>{" "}
                                                </div>
                                              ) : (
                                                false
                                              )
                                          )
                                        : false}
                                    </div>
                                  ) : (
                                    false
                                  )}{" "}
                                </div>
                              ) : (
                                false
                              )}

                              {arritemschatmessage.indicate_new === "y" ? (
                                <>
                                  <div className="chat_indicate_new_message">
                                    У вас непрочитанные сообщения
                                  </div>
                                </>
                              ) : (
                                false
                              )}
                            </div>
                          </Fragment>
                        );
                      }
                    }
                  )}
                </>
              </div>
            </>
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {active_chat && active_chat !== "1" ? (
        <div className="chat_right_bottom ">
          <div className="container">
            <div className="row">
              {selectedFileChat ? (
                <div
                  className={`file_paste_block ${
                    selectedFileChat[0] && selectedFileChat[0].name
                      ? "active"
                      : null
                  }
                              
                              ${activeMessageForAnswer ? "active_top" : null}
                              
                              `}
                >
                  <div className="file_paste_items">
                    {selectedFileChat.map((fileInState, index) => (
                      <div key={index} className="file_paste_item">
                        {fileInState.type.startsWith("image/") ? (
                          <img
                            src={URL.createObjectURL(fileInState)}
                            alt="document"
                          ></img>
                        ) : (
                          <div class="file_paste_item_other">
                            {fileInState.name}
                          </div>
                        )}

                        <div
                          className="file_paste_del"
                          onClick={() => deleteFileFromState(index)}
                        >
                          <FiTrash />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                false
              )}

              <div
                className={`chat_answer_area ${
                  activeMessageForAnswer ? "active" : null
                }`}
              >
                {activeMessageForAnswer ? (
                  <div>
                    <div className="chat_answer_area_icon">
                      <IoArrowUndoOutline />
                    </div>

                    <div className="chat_answer_area_user">
                      {message_list &&
                      message_list[activeMessageForAnswer].user &&
                      message_list[activeMessageForAnswer].user.value
                        ? message_list[activeMessageForAnswer].user.value
                        : false}
                    </div>
                    <div className="chat_answer_area_message">
                      {message_list &&
                      message_list[activeMessageForAnswer].message &&
                      message_list[activeMessageForAnswer].message.value
                        ? message_list[activeMessageForAnswer].message.value
                        : false}
                    </div>
                    <div
                      className="chat_answer_area_close"
                      onClick={() => setActiveMessageForAnswer(false)}
                    >
                      <IoClose />
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>

              <div className="lower-chat-bar">
                <div className="add_file_chat">
                  <form className="add_file_label">
                    <input
                      id="btnFromAddFileChat1"
                      className="class_display_none"
                      type="file"
                      onChange={addOnChange}
                    />
                    <label htmlFor="btnFromAddFileChat1">
                      <IoAttachOutline />
                    </label>
                  </form>
                </div>

                <div
                  className="emoji-button-open"
                  onClick={() => setEmojiOpen(!emojiOpen)}
                >
                  <MdOutlineEmojiEmotions />
                  <div
                    className={`emoji-container ${emojiOpen ? "active" : ""}`}
                  >
                    {symbolEmojis
                      ? symbolEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            onClick={() => addEmoji(emoji.symbol)}
                          >
                            {emoji.symbol}
                          </div>
                        ))
                      : false}
                  </div>
                </div>

                <div className="chat_textarea_rows">
                  <textarea
                    id="chat_textarea_comment"
                    onKeyDown={(event) => {
                      handleKeyPress(event);
                    }}
                    rows="1"
                    placeholder="Введите текст сообщения..."
                  ></textarea>
                </div>

                <div
                  className="chat_textarea_message_button"
                  onClick={() => {
                    var textareaValue = $("#chat_textarea_comment").val();
                    getChat(active_chat, "add_message", "", textareaValue);
                    $("#chat_textarea_comment").val("");
                  }}
                >
                  <IoSendSharp />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>загрузка</>
      )}
    </>
  );
}

export default MessageList;
