import { useState, useEffect } from "react";
import GetData from "../servicdesk/getdata/GetData";
import { FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";
import { arrListСounterpartieContacts } from "../servicdesk/const/ConstTicket";
import avatar from "../../../image/png/avatar.png";
import "./staff.css";

function Staff() {
  const [listStaff, setListStaff] = useState([]);

  useEffect(() => {
    getStaff();
  }, []);

  const getStaff = async (id_item, action) => {
    const request_parameters = {
      module: "settings",
      block: "users",
    };
    const request_attribute = {
      type_data: "list_users",
      id_item: id_item,
      action: action,
    };

    const result = await GetData(request_parameters, request_attribute);

    const arrListStaff =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];
    setListStaff(arrListStaff);
  };

  return (
    <>
      <div className="staff_all_content">
        <div className="container">
          <div className="title_h1_staff_add">
            <div className="a-text-1 a-section-title">Сотрудники</div>
            <div
              className="a-btn_block_add"
              onClick={() => getStaff("", "add")}
            >
              Добавить сотрудника
            </div>
          </div>

          <div className="table_staff border_background">
            <div>
              <div className="staff_title_table_block">
                <div className="staff_title_table">
                  <div className="staff_title_item">
                    {arrListСounterpartieContacts &&
                    arrListСounterpartieContacts.avatar &&
                    arrListСounterpartieContacts.avatar.title ? (
                      <div className="staff_title_unit">{<div></div>}</div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="staff_title_item">
                    {arrListСounterpartieContacts &&
                    arrListСounterpartieContacts.name &&
                    arrListСounterpartieContacts.name.title ? (
                      <div className="staff_title_unit">
                        {arrListСounterpartieContacts.name.title}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="staff_title_item">
                    {arrListСounterpartieContacts &&
                    arrListСounterpartieContacts.mail &&
                    arrListСounterpartieContacts.mail.title ? (
                      <div className="staff_title_unit">
                        {arrListСounterpartieContacts.mail.title}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="staff_title_item">
                    {arrListСounterpartieContacts &&
                    arrListСounterpartieContacts.phone &&
                    arrListСounterpartieContacts.phone.title ? (
                      <div className="staff_title_unit">
                        {arrListСounterpartieContacts.phone.title}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="staff_title_item">
                    {arrListСounterpartieContacts &&
                    arrListСounterpartieContacts.position &&
                    arrListСounterpartieContacts.position.title ? (
                      <div className="staff_title_unit">
                        {arrListСounterpartieContacts.position.title}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="staff_title_item">
                    {arrListСounterpartieContacts &&
                    arrListСounterpartieContacts.position &&
                    arrListСounterpartieContacts.position.title ? (
                      <div className="staff_title_unit">
                        {arrListСounterpartieContacts.online.title}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
            {listStaff
              ? listStaff.map((listStaffvalue, index) => (
                  <div className="staff_item" key={index}>
                    {listStaffvalue.image.value ? (
                      <div className="staffvalue_image__name">
                        <div
                          className={`staff_value_items_unit staff_value_image ${
                            listStaffvalue.last_activity.online == "y"
                              ? "online"
                              : ""
                          }`}
                        >
                          <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                            <img src={listStaffvalue.image.value}></img>
                          </Link>
                        </div>
                        {listStaffvalue.name.value ? (
                          <div className="staff_value_items_unit title_h5">
                            <Link
                              to={"/staff/" + listStaffvalue.id.value + "/"}
                            >
                              {listStaffvalue.name.value}
                            </Link>
                          </div>
                        ) : (
                          <div>
                            <Link
                              to={"/staff/" + listStaffvalue.id.value + "/"}
                            >
                              Не заполнено
                            </Link>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="staffvalue_image__name">
                        <div className="staff_value_items_unit staff_value_image">
                          <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                            <img src={avatar} alt="logo" />
                          </Link>
                        </div>
                        {listStaffvalue.name.value ? (
                          <div className="staff_value_items_unit title_h5">
                            <Link
                              to={"/staff/" + listStaffvalue.id.value + "/"}
                            >
                              {listStaffvalue.name.value}
                            </Link>
                          </div>
                        ) : (
                          <div>
                            <Link
                              to={"/staff/" + listStaffvalue.id.value + "/"}
                            >
                              Не заполнено
                            </Link>
                          </div>
                        )}
                      </div>
                    )}

                    {listStaffvalue.mail.value ? (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          {listStaffvalue.mail.value}
                        </Link>
                      </div>
                    ) : (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          Не заполнено
                        </Link>
                      </div>
                    )}
                    {listStaffvalue.phone.value ? (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          {listStaffvalue.phone.value}
                        </Link>
                      </div>
                    ) : (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          Не заполнено
                        </Link>
                      </div>
                    )}
                    {listStaffvalue.position.value ? (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          {listStaffvalue.position.value}
                        </Link>
                      </div>
                    ) : (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          Не заполнено
                        </Link>
                      </div>
                    )}
                    {listStaffvalue.position.value ? (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          {listStaffvalue.last_activity.value}
                        </Link>
                      </div>
                    ) : (
                      <div className="staff_value_items_unit">
                        <Link to={"/staff/" + listStaffvalue.id.value + "/"}>
                          Не заполнено
                        </Link>
                      </div>
                    )}
                    <div></div>
                    <div
                      className="block_delete_fitrash staff_value_items_unit"
                      onClick={() => {
                        if (window.confirm("Удалить?")) {
                          getStaff(listStaffvalue.id.value, "delete");
                        }
                      }}
                    >
                      <FiTrash />
                    </div>
                  </div>
                ))
              : false}
          </div>
        </div>
      </div>
    </>
  );
}

export default Staff;
