import $ from "jquery";
import GetData from "components/unit/servicdesk/getdata/GetData";
import { useState, useEffect } from "react";
import Captcha from "./Captcha";
import Spinner from "react-bootstrap/Spinner";
import "./auth.css";

function Auth({ propsType, setPropsType }) {
  const [failText, setFailText] = useState(false);
  const [resultKeyCaptcha, setResultKeyCaptcha] = useState(false);
  const [captchaResult, setCaptchaResult] = useState(false);
  const [rebootCaptcha, setRebootCaptcha] = useState(false);
  const [inactiveButton, setInactiveButton] = useState(false);
  const [phone, setPhone] = useState("");
  const [status, setSatus] = useState(false);
  const [statusSendCode, setSatusSendCode] = useState("");
  const [propsTypeModule, setPropsTypeModule] = useState("");
  const [getparam, setGetParam] = useState("");

  useEffect(() => {
    if (propsType && propsType === "auth") {
      setPropsTypeModule({
        title: "Вход в личный кабинет",
        subTitle: "Телефон",
        buttonText: "Войти",
        codeButtonText: "Отправить",
        codeTitle: "Код из смс",
        subButtonText: "Зарегистрироваться",
        url: "/registration/",
      });
    }
    if (propsType && propsType === "registration") {
      setPropsTypeModule({
        title: "Регистрация",
        subTitle: "Телефон",
        buttonText: "Зарегистрироваться",
        subButtonText: "Есть аккаунт? Войти",
        url: "/auth/",
        codeButtonText: "Отправить",
      });
    }
  }, [propsType]);

  useEffect(() => {
    const locationSearch = window.location.search;
    const paramsNew = new URLSearchParams(locationSearch);
    const typeModule = paramsNew.get("reg_module");
    setGetParam(typeModule || "");
  }, []);

  async function AuthKey(code, block) {
    if (phone.length === 18) {
      setFailText(false);
      const request_parameters = {
        module: "auth",
        block: block,
      };
      const request_attribute = {
        type: propsType,
        reg_module: getparam,
      };
      const request_data = {
        value: phone,
        code: code,
      };

      setInactiveButton(true);
      const result = await GetData(request_parameters, request_attribute, request_data);

      setInactiveButton(false);

      if (result && result.request_data && result.request_data.url && result.request_data.url.length > 0) {
        const currentUrl = window.location.href;
        const containsLocation = currentUrl.includes("localhost");

        if (containsLocation) {
          window.location.replace("http://localhost:3000/task/");
        } else {
          window.location.replace(result.request_data.url + "task/");
        }
      } else {
        const arrStatus = result && result.request_data && result.request_data.items ? result.request_data.items : [];

        setSatus(arrStatus);
      }

      const arrStatusCode =
        result && result.request_data && result.request_data.items && result.request_data.items.status_send_code
          ? result.request_data.items.status_send_code
          : [];

      if (!statusSendCode && arrStatusCode === "y") {
        setSatusSendCode(true);
      }
    } else {
      setFailText("Неверный формат телефона");
    }
  }

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Убираем все нецифровые символы

    // Если номер начинается с 8, заменяем её на 7
    if (value.startsWith("8")) {
      value = "7" + value.slice(1); // Если начинаем с 8, заменяем на 7
    }

    // Если номер начинается с 9, добавляем +7
    if (value.startsWith("9")) {
      value = "79" + value.slice(1); // Если начинается с 9, добавляем +7
    }

    // Если пользователь начинает вводить номер с "+", то сохраняем "+" и продолжаем форматировать номер
    if (e.target.value.startsWith("+")) {
      value = value; // Просто сохраняем число после "+"
    }

    // Ограничиваем длину номера до 12 цифр
    if (value.length > 12) {
      value = value.slice(0, 12); // Даем максимум 12 цифр
    }

    // Форматируем номер в формат +7 (XXX) XXX-XX-XX
    let formattedValue = "";

    // Если есть хотя бы одна цифра, начинаем с "+" и первой цифры
    if (value.length > 0) {
      formattedValue += "+" + value[0]; // Первое число будет всегда 7 или 9
    }

    if (value.length > 1) {
      formattedValue += " (" + value.slice(1, 4); // Форматируем скобки для первых 3 цифр
    }
    if (value.length > 4) {
      formattedValue += ") " + value.slice(4, 7); // Форматируем пробел и дефис
    }
    if (value.length > 7) {
      formattedValue += "-" + value.slice(7, 9); // Дефис между 8-й и 9-й цифрой
    }
    if (value.length > 9) {
      formattedValue += "-" + value.slice(9, 12); // Дефис между 10-й и 12-й цифрой
    }

    // Устанавливаем отформатированное значение в состояние
    setPhone(formattedValue);
  };

  return (
    <>
      <div className="auth">
        <div className="all-content">
          {!statusSendCode ? (
            <div className="authblock border_background">
              <div>
                <div className="authblock-tittle">{propsTypeModule.title}</div>
                <div className="authblock-phone">{propsTypeModule.subTitle}</div>
                <div className="authblock_parth">
                  <div className="textarea-phone">
                    <input
                      className="input-auth"
                      id="phone"
                      type="tel"
                      placeholder="+7 (___) ___-__-__"
                      value={phone}
                      onInput={handlePhoneChange}
                      maxLength="18"
                      required
                    ></input>
                  </div>
                  <div className="authblock-fail">{failText ? failText : false}</div>

                  <div className="authblock-captcha">
                    <Captcha
                      propssetResultKeyCaptcha={setResultKeyCaptcha}
                      propsresultKeyCaptcha={resultKeyCaptcha}
                      propssetCaptchaResult={setCaptchaResult}
                      rebootCaptcha={rebootCaptcha}
                      setRebootCaptcha={setRebootCaptcha}
                    />
                    {captchaResult ? <div className="captcharesult-text">{captchaResult}</div> : false}
                  </div>

                  <div
                    className="authblock-registration"
                    onClick={() => {
                      window.location.replace(propsTypeModule.url);
                      setPropsType("auth");
                    }}
                  >
                    {propsTypeModule.subButtonText}
                  </div>
                  <div className="input-statuss">{status.status_text}</div>

                  <div className="authblock-button-enter">
                    {inactiveButton === false ? (
                      <div
                        className="authblock-send-button btn_one"
                        onClick={() => {
                          if (resultKeyCaptcha && resultKeyCaptcha === true) {
                            AuthKey("", "send_code");
                            setCaptchaResult(false);
                          } else {
                            setCaptchaResult("Ошибка в проверочном коде");
                            setRebootCaptcha(true);
                            setFailText();
                          }
                        }}
                      >
                        {propsTypeModule.buttonText}
                      </div>
                    ) : (
                      <div className="authblock-send-button">
                        <Spinner animation="border" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="authblock border_background">
              <div>
                <div className="authblock-tittle">{propsTypeModule.title}</div>
                <div className="authblock-code">{propsTypeModule.codeTitle}</div>
                <div className="authblock_parth">
                  <div className="textarea-phone">
                    <input className="input-auth-code" id="code" type="number" placeholder="" maxLength="6" minLength="6" required></input>
                  </div>
                  <div className="authblock-fail">{failText ? failText : false}</div>

                  <div
                    className="authblock-registration"
                    onClick={() => {
                      window.location.replace("/registration/");
                      setPropsType("registration");
                    }}
                  >
                    {propsTypeModule.subButtonText}
                  </div>
                  <div className="input-statuss">{status.status_text}</div>
                  <div className="authblock-button-enter">
                    {inactiveButton === false ? (
                      <div
                        className="authblock-send-button btn_one"
                        onClick={() => {
                          var code = $("#code").val();
                          AuthKey(code, "input_code");
                        }}
                      >
                        {propsTypeModule.codeButtonText}
                      </div>
                    ) : (
                      <div className="authblock-send-button">
                        <Spinner animation="border" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Auth;
