import FunctionChange from "components/general/FunctionChange";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetData from "../servicdesk/getdata/GetData";
import "./crm_contact.css";

const CrmContact = ({ centralBlock }) => {
  const [contact, setContact] = useState([]);
  const { id, type } = useParams();

  useEffect(() => {
    setContact(centralBlock);
  }, [centralBlock]);

  const getCrmContact = async (edit_field, value, id_item) => {
    const request_parameters = {
      module: "crm",
      block: type,
    };
    const request_attribute = {
      id: id,
      area: "contact",
      action: "edit_contact",
      id_item: id_item,
    };
    const request_data = {
      edit_field: edit_field,
      value: value,
    };

    const result = await GetData(
      request_parameters,
      request_attribute,
      request_data
    );

    const arrContact =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    setContact(arrContact);
  };

  function getValue(edit_field, value, id_item) {
    getCrmContact(edit_field, value, id_item);
  }

  return (
    <>
      {contact ? (
        <div>
          <div className="a-text-2 border_bg_b_c_three pb-2">
            Контактные данне
          </div>

          <div className="contact-item-block">
            <div className="contact-item">
              <div className="a-text-4">ФИО</div>
              <FunctionChange
                getValue={getValue}
                propsValue={
                  contact.name &&
                  contact.name.value &&
                  contact.name.value.length > 0
                    ? contact.name.value
                    : ""
                }
                fieldType={
                  contact.name &&
                  contact.name.field &&
                  contact.name.field.length > 0
                    ? contact.name.field
                    : ""
                }
                propsIditem={id}
              />
            </div>
            <div className="contact-item">
              <div className="a-text-4">Телефон</div>
              <FunctionChange
                getValue={getValue}
                propsValue={
                  contact.phone &&
                  contact.phone.value &&
                  contact.phone.value.length > 0
                    ? contact.phone.value
                    : ""
                }
                fieldType={
                  contact.phone &&
                  contact.phone.field &&
                  contact.phone.field.length > 0
                    ? contact.phone.field
                    : ""
                }
                propsIditem={id}
              />
            </div>
            <div className="contact-item">
              <div className="a-text-4">Электронная почта</div>
              <FunctionChange
                getValue={getValue}
                propsValue={
                  contact.mail &&
                  contact.mail.value &&
                  contact.mail.value.length > 0
                    ? contact.mail.value
                    : ""
                }
                fieldType={
                  contact.mail &&
                  contact.mail.field &&
                  contact.mail.field.length > 0
                    ? contact.mail.field
                    : ""
                }
                propsIditem={id}
              />
            </div>
            <div className="contact-item">
              <div className="a-text-4">Адрес</div>
              <FunctionChange
                getValue={getValue}
                propsValue={
                  contact.address &&
                  contact.address.value &&
                  contact.address.value.length > 0
                    ? contact.address.value
                    : ""
                }
                fieldType={
                  contact.address &&
                  contact.address.field &&
                  contact.address.field.length > 0
                    ? contact.address.field
                    : ""
                }
                propsIditem={id}
              />
            </div>
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
};
export default CrmContact;
