import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GetData from "../servicdesk/getdata/GetData";
import FunctionChange from "components/general/FunctionChange";
import { FiTrash } from "react-icons/fi";
import "./widget-feed.css";

function WidgetFeedSearch() {
  const { id } = useParams();
  const [listWidgetFeedSearch, setListWidgetFeedSearch] = useState([]);
  const [download, setDownload] = useState(false); // ожидание получении данных
  useEffect(() => {
    getWidgetFeedSearch();
  }, []);

  const getWidgetFeedSearch = async (action, value, id_feed, edit_field) => {
    if (download === true) {
    } else {
      setDownload(true);
      const request_parameters = {
        module: "search",
        block: "feeds",
      };
      const request_attribute = {
        action: action,
        id_item: id,
        id_feed: id_feed,
      };
      const request_data = {
        edit_field: edit_field,
        value: value,
      };
      const result = await GetData(
        request_parameters,
        request_attribute,
        request_data
      );

      const arrWidgetFeedSearch =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];

      setListWidgetFeedSearch(arrWidgetFeedSearch);
      setDownload(false);
    }
  };

  const handleChange = (event) => {
    getWidgetFeedSearch("", event.target.value);
  };

  function getValue(fieldType, value, id_feed) {
    getWidgetFeedSearch("edit", value, id_feed, fieldType);
  }

  return (
    <>
      <div className="search_feed_all_content">
        <div className="container">
          <div className="header-search">
            <div className="title_h1_search_add">
              <div className="a-text-1 a-section-title">Фиды</div>
            </div>

            <div className="block-input-btn-block">
              <div className="block-input-btn">
                <input
                  id="custom-input"
                  type="text"
                  onChange={handleChange}
                  placeholder="Найти"
                />
              </div>
              <div className="a-btn_block_add-area">
                <div
                  className="a-btn_block_add"
                  onClick={() => getWidgetFeedSearch("new_feed")}
                >
                  Добавить фид
                </div>
              </div>
            </div>
          </div>
          <div className="border_background widget-body-content">
            <div className="widget-table-items">
              <div className="widget-table-item">Название</div>
              <div className="widget-table-item">URL</div>
              <div className="widget-table-item">Статус</div>
              <div className="widget-table-item"></div>
            </div>
            {listWidgetFeedSearch
              ? listWidgetFeedSearch.map((listwidgetfeedmap, index) =>
                  listwidgetfeedmap ? (
                    <div key={index}>
                      <div className="widget-table-items">
                        <div className="widget-table-item">
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              listwidgetfeedmap.name
                                ? listwidgetfeedmap.name
                                : "Добавьте название"
                            }
                            propsIditem={listwidgetfeedmap.id}
                            fieldType={"name"}
                          />
                        </div>
                        <div className="widget-table-item">
                          <FunctionChange
                            getValue={getValue}
                            propsValue={
                              listwidgetfeedmap.url
                                ? listwidgetfeedmap.url
                                : "Добавьте ссылку"
                            }
                            propsIditem={listwidgetfeedmap.id}
                            fieldType={"url"}
                          />
                        </div>
                        <div className="widget-table-item">
                          <div
                            onClick={() =>
                              getWidgetFeedSearch(
                                "edit",
                                listwidgetfeedmap.active === "n" ? "y" : "n",
                                listwidgetfeedmap.id
                                  ? listwidgetfeedmap.id
                                  : false,
                                "active"
                              )
                            }
                            className={`switch ${
                              listwidgetfeedmap.active &&
                              listwidgetfeedmap.active === "y"
                                ? "active"
                                : ""
                            }`}
                          ></div>
                        </div>
                        <div className="widget-table-item">
                          {" "}
                          <div
                            className="block_delete_fitrash search-btn-delete"
                            onClick={() => {
                              if (window.confirm("Удалить?")) {
                                getWidgetFeedSearch(
                                  "del_feed",
                                  "",
                                  listwidgetfeedmap.id
                                );
                              }
                            }}
                          >
                            <FiTrash />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )
                )
              : false}
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetFeedSearch;
