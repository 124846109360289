import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import getData from "../getdata/GetData";
import { arrSelectors } from "../const/DataSelectors";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import "./filterpanel.css";
import {
  IoSearchOutline,
  IoCaretDownSharp,
  IoCheckboxSharp,
} from "react-icons/io5";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

function FilterPanel({ changeListState }) {
  const [activeSelector, stateActiveSelector] = useState(null);
  const [activeListSelector, stateActiveListSelector] = useState(null);
  const [arrDefaulValue, setArrDefaulValue] = useState([]);
  const [isOpenMobileFilter, setIsOpenMobileFilter] = useState(false);
  const [timeoutSearch, setTimeoutSearch] = useState(null); //Задержка поиска

  const handleOutsideClick = (event) => {
    if (
      !event.target.closest(".filter_selector_option") &&
      !event.target.closest(".filter_panel_selectiontext")
    ) {
      stateActiveListSelector(null);
      stateActiveSelector(null);
      getDefaultValue();
    }
  };

  useEffect(() => {
    getDefaultValue();
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  async function getListSelector(getTypeSelector) {
    const module = arrSelectors[getTypeSelector].module;
    const block = arrSelectors[getTypeSelector].block;

    const request_parameters = { module: module, block: block, type: "get" };
    const request_attribute = { content: { value: "name" }, for: "tickets" };
    const result = await getData(request_parameters, request_attribute);

    let arrItemsSelector =
      result && result.request_data && result.request_data.items
        ? result.request_data.items
        : [];

    if (getTypeSelector === "status") {
      stateActiveListSelector(arrItemsSelector);
    } else {
      stateActiveListSelector(arrItemsSelector);
    }
  }

  function getDefaultValue() {
    const getDefaultValueAsync = async () => {
      const request_parameters = {
        module: "settings",
        block: "filterdefault",
        type: "get",
      };
      const request_attribute = { for: "tickets" };
      const result = await getData(request_parameters, request_attribute);
      const arrFilterDefaultValue =
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [];
      setArrDefaulValue(arrFilterDefaultValue);
    };
    getDefaultValueAsync();
  }

  function onClickSelector(typeSelector) {
    stateActiveSelector((prevActiveSelector) => {
      if (prevActiveSelector !== typeSelector) {
        stateActiveListSelector(null);
        getDefaultValue();
      }

      const newActiveSelector = typeSelector;
      getListSelector(typeSelector);
      return newActiveSelector;
    });
  }

  //Задержка поиска
  const handleChangeSearch = (e) => {
    const value = e.target.value;

    // Очищаем предыдущий таймер
    if (timeoutSearch) {
      clearTimeout(timeoutSearch);
    }

    // Запускаем новый таймер на 2 секунды
    const searchTimer = setTimeout(() => {
      changeListState("search", "ticket", value);
    }, 1000);

    // Сохраняем значение таймера в состоянии
    setTimeoutSearch(searchTimer);
  };

  let expand = "xxl";
  if (window.screen.width <= 576) {
    expand = "mobile";
  }

  return (
    <>
      {/* Открытие меню в мобильной версии */}
      {expand === "mobile" ? (
        <div className="col-3 mobile-filter">
          <div
            className="mobile-filter-panel"
            onClick={() => setIsOpenMobileFilter(!isOpenMobileFilter)}
          >
            {isOpenMobileFilter && isOpenMobileFilter === true ? (
              <MdFilterAltOff />
            ) : (
              <CiFilter />
            )}
          </div>
        </div>
      ) : (
        false
      )}
      <div
        className={`filter_panel ${
          isOpenMobileFilter === true ? "active" : false
        } border_background`}
      >
        {Object.keys(arrSelectors).map((selectorKey, index) => {
          const selector = arrSelectors[selectorKey];

          // search
          if (selector.type === "inputsearch") {
            return (
              <div key="search01" className="filter_search">
                <div className="filter_search_elementinput">
                  <input
                    type="search"
                    placeholder={selector.default}
                    onChange={handleChangeSearch}
                  ></input>
                  <IoSearchOutline />
                </div>
              </div>
            );
          }

          // selector
          if (selector.type === "selector") {
            const countActiveOption = arrDefaulValue[selectorKey];
            const defaultTittle =
              countActiveOption > 0
                ? "Выбрано: " + countActiveOption
                : selector.default;
            return (
              <div key={index} className="filter_item">
                <div className="filter_panel_selection">
                  <div className="filter_panel_tittle">{selector.title}</div>

                  <div className="filter_panel_selectiontext_block">
                    <div className="filter_panel_selectiontext_item">
                      <div
                        key={`filterIndexGroupArea_${index}`}
                        onClick={() => {
                          onClickSelector(selectorKey);
                        }}
                        className={`filter_panel_selectiontext ${
                          activeSelector === selectorKey
                            ? "opening_filter_select"
                            : ""
                        }`}
                      >
                        {activeSelector === selectorKey ? (
                          activeListSelector ? (
                            selectorKey === "status" ? (
                              <div key={`filterIndexGroupStatus_${index}`}>
                                {activeListSelector.map(
                                  (groupItem, groupIndex) => {
                                    return (
                                      <div
                                        key={`filterIndexGroupListItems_${index}_${groupIndex}`}
                                      >
                                        {groupItem.id > 0 && groupItem.name ? (
                                          <div
                                            key={`filterIndexGroupTitle_${index}_${groupIndex}`}
                                            className={`filter_selector_option  ${
                                              groupItem.status === "Y"
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              changeListState(
                                                "filter",
                                                selectorKey + "_group",
                                                groupItem.id
                                              );
                                              onClickSelector(selectorKey);
                                            }}
                                          >
                                            <div className="icon_items__selector_value">
                                              <div className="icon_items">
                                                {groupItem.status === "Y" ? (
                                                  <IoCheckboxSharp />
                                                ) : (
                                                  <MdCheckBoxOutlineBlank />
                                                )}
                                              </div>
                                              <div className="selector_value">
                                                {groupItem.name}
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          false
                                        )}

                                        <div
                                          key={`filterIndexGroupBlock_${index}_${groupIndex}`}
                                          className={
                                            groupItem.id > 0
                                              ? "group_block"
                                              : ""
                                          }
                                        >
                                          {groupItem.value.length > 0
                                            ? groupItem.value.map(
                                                (items, index) => (
                                                  <div
                                                    key={`filterIndex_${index}_${groupIndex}`}
                                                    className={`filter_selector_option  ${
                                                      items.active === "Y"
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() => {
                                                      changeListState(
                                                        "filter",
                                                        selectorKey,
                                                        items.id
                                                      );
                                                      onClickSelector(
                                                        selectorKey
                                                      );
                                                    }}
                                                  >
                                                    <div className="icon_items__selector_value">
                                                      <div className="icon_items">
                                                        {items.active ===
                                                        "Y" ? (
                                                          <IoCheckboxSharp />
                                                        ) : (
                                                          <MdCheckBoxOutlineBlank />
                                                        )}
                                                      </div>
                                                      <div className="selector_value">
                                                        {items.value}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )
                                            : false}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              Object.keys(activeListSelector).map(
                                (itemsKey, itemsIndex) => {
                                  const items = activeListSelector[itemsKey];
                                  return (
                                    <div
                                      key={`filterIndex_${index}_${itemsIndex}`}
                                      className={`filter_selector_option  ${
                                        items.active === "Y" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        changeListState(
                                          "filter",
                                          selectorKey,
                                          items.id
                                        );
                                        onClickSelector(selectorKey);
                                      }}
                                    >
                                      <div className="icon_items__selector_value">
                                        <div className="icon_items">
                                          {items.active === "Y" ? (
                                            <IoCheckboxSharp />
                                          ) : (
                                            <MdCheckBoxOutlineBlank />
                                          )}
                                        </div>
                                        <div className="selector_value">
                                          {items.value}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            )
                          ) : (
                            <div>Нет данных</div>
                          )
                        ) : (
                          <div className="filter_selector_option">
                            {defaultTittle}
                          </div>
                        )}
                      </div>

                      <div className="icon_selector_filter_panel">
                        {activeSelector === selectorKey ? (
                          false
                        ) : (
                          <IoCaretDownSharp />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </>
  );
}

export default FilterPanel;
